<template>
  <v-row>
    <v-col cols="12" sm="4" class="border-col">
      <formmonto :autofocusSet="true" labelSet="¿Cuanto dinero requieres?" :validatingSet="validating" :valorSet="montoSolicitar"
      @SetValor="SetMontoCredito" ref="montoSolicitar" textoAlternativoSet="99999999" MensajeAyudaSet="Ingreso el monto entre 500.000 y 99.000.000"
       valorMinimoSet="500000" valorMaximoSet="99000000" :requeridoSet="true" preIconoSet="mdi-currency-usd"></formmonto>
    </v-col>

    <v-col cols="12" sm="4" class="border-col">
      <formmonto labelSet="¿En cuantas cuotas?" MensajeAyudaSet="Número de cuotas entre 6 y 72" textoAlternativoSet="99"
         :validatingSet="validating" @SetValor="SetCantidadCuotas" preIconoSet="mdi-timeline-clock-outline" requeridoSet="true"
          valorMaximoSet="72" valorMinimoSet="6"></formmonto>
    </v-col>

    <v-col cols="12" sm="4" class="border-col">
      <formfecha labelSet="¿Para cuando la primera cuota?" :minFecSet="MinFec" MensajeAyudaSet="Fecha en que desea pagar la primera cuota"
       :validatingSet="validating" @SetValor="SetFechaCuotas" requeridoSet="true"></formfecha>
    </v-col>
    <v-col cols="12" class="border-col ml-4">
      ¿Quieres agregar seguros a tu crédito?
      <v-row>
        <v-col cols="12" sm="4" class="border-col">
          <v-switch
            label="Degravamen"
            color="primary"
            :loading="validating"
            :disabled="validating"
            v-model="sd"
          ></v-switch>
        </v-col>
        <v-col cols="12" sm="4" class="border-col">
          <v-switch
            label="Cesantía involuntaria"
            color="primary"
            :loading="validating"
            :disabled="validating"
            v-model="ci"
          ></v-switch>
        </v-col>
        <v-col cols="12" sm="4" class="border-col">
          <v-switch
            label="Incapacidad temporal"
            color="primary"
            :loading="validating"
            :disabled="validating"
            v-model="it"
          ></v-switch>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" class="border-col">
      <formText labelSet="Nombre del crédito" :requeridoSet="true" iconoSet="mdi-bank-outline" :maxLengthSet="50" :minLengthSet="3"
       MensajeAyudaSet="Nombre que le ayude a recordar para lo que necesita el crédito" textoAlternativoSet="Nombre del crédito"
       @SetValor="SetNombreCredito" :validatingSet="validating"></formText>
    </v-col>
    <v-col cols="12" class="border-col">
      <v-textarea
        label="Destino del crédito"
        v-model="descripcionCredito"
        variant="outlined"
        density="compact"
        autocomplete="false"
        :loading="validating"
        :disabled="validating"
        prepend-inner-icon="mdi-image-text"
        rows="2"
        hint="Descripción de la necesidad del dinero, esto ayuda a los ejecutivos a darte mejores opciones o consideraciones"
      ></v-textarea>
    </v-col>

    <v-col cols="12" sm="4" class="border-col">
      <formmonto labelSet="Ingreso líquido" :validatingSet="validating" :valorSet="sueldoLiquido" textoAlternativoSet="99999999"
      @SetValor="SetMontoSueldoLiquido" MensajeAyudaSet="Corresponde a los ingresos mensuales que tienes." preIconoSet="mdi-currency-usd" 
      requeridoSet="true" valorMaximoSet="100000000" valorMinimoSet="400000"></formmonto>
    </v-col>

    <v-col cols="12" sm="4" class="border-col">
      
      <formmonto labelSet="¿Pago mensual?" :validatingSet="validating" :valorSet="deudaMensual" textoAlternativoSet="99999999."
      @SetValor="SetMontoGastoMensual" MensajeAyudaSet="Corresponde al monto que paga mensualmente por sus deudas." preIconoSet="mdi-cash" 
      requeridoSet="true" valorMaximoSet="200000000" valorMinimoSet="0"></formmonto>
    </v-col>

    <v-col cols="12" sm="4" class="border-col">
      
      <formmonto labelSet="¿Deuda total?" :validatingSet="validating" :valorSet="deudaTotal" textoAlternativoSet="99999999"
      @SetValor="SetMontoDeudaTotal" MensajeAyudaSet="Corresponde al monto total que debe a todas las entdades financieras al día de hoy." 
      preIconoSet="mdi-cash-multiple" requeridoSet="true" valorMaximoSet="200000000" valorMinimoSet="0"></formmonto>
    </v-col>
  </v-row>
</template>

<script>
import {
  enviarJsonPOST,
  formatoFechaVue,
  agregarMeses,
} from "@/helpers/funcionesEstandard";
import {
  rulesRut,
  rulesMail,
  rulesName,
  rulesApellido,
  rulesEmpresa,
  rulesClave,
  rulesNameCredito,
  rulesMonedaMayIgCero,
  rulesSueldo,
  rulesMontoCredConsumo,
  rulesCuotasConsumo,
} from "@/helpers/funcionesEstandard";

import formmonto from "@/components/form/numero.vue";
import formcuotas from "@/components/form/cantidadCotas.vue";
import formfecha from "@/components/form/fecha.vue";
import formText from "@/components/form/textos.vue";

export default {
  data: () => ({
    show: false,
    descripcionCredito: null,
    sueldoLiquido: null,
    deudaMensual: null,
    deudaTotal: null,
    montoSolicitar: null,
    numeroCuotas: null,
    fechaPrimeraCuota: null,
    sd: null,
    ci: null,
    it: null,

    MaxFec: null,
    MinFec: null,

    rulesMail: rulesMail,
    rulesRut: rulesRut,
    rulesName: rulesName,
    rulesApellido: rulesApellido,
    rulesEmpresa: rulesEmpresa,
    rulesClave: rulesClave,
    rulesNameCredito: rulesNameCredito,
    rulesMonedaMayIgCero: rulesMonedaMayIgCero,
    rulesSueldo: rulesSueldo,
    rulesMontoCredConsumo: rulesMontoCredConsumo,
    rulesCuotasConsumo: rulesCuotasConsumo,
  }),
  props: {
    validating: Boolean,
  },
  components: {
    formmonto,
    formcuotas,
    formfecha,
    formText,
  },
  watch: {
    descripcionCredito: function (val) {
      this.$emit("setValConsumo2", "descripcionCredito", val);
      this.$emit("setValConsumo2", "codigo_tipo_credito", "CRECO");
    },
    sueldoLiquido: function (val) {
      this.$emit("setValConsumo2", "sueldoLiquido", val);
      this.$emit("setValConsumo2", "codigo_tipo_credito", "CRECO");
    },
    deudaMensual: function (val) {
      this.$emit("setValConsumo2", "deudaMensual", val);
      this.$emit("setValConsumo2", "codigo_tipo_credito", "CRECO");
    },
    deudaTotal: function (val) {
      this.$emit("setValConsumo2", "deudaTotal", val);
      this.$emit("setValConsumo2", "codigo_tipo_credito", "CRECO");
    },
    montoSolicitar: function (val) {
      this.$emit("setValConsumo2", "montoSolicitar", val);
      this.$emit("setValConsumo2", "codigo_tipo_credito", "CRECO");
    },
    numeroCuotas: function (val) {
      this.$emit("setValConsumo2", "numeroCuotas", val);
      this.$emit("setValConsumo2", "codigo_tipo_credito", "CRECO");
    },
    fechaPrimeraCuota: function (val) {
      this.$emit("setValConsumo2", "fechaPrimeraCuota", val);
      this.$emit("setValConsumo2", "codigo_tipo_credito", "CRECO");
    },
    sd: function (val) {
      this.$emit("setValConsumo2", "sd", val);
    },
    ci: function (val) {
      this.$emit("setValConsumo2", "ci", val);
    },
    it: function (val) {
      this.$emit("setValConsumo2", "it", val);
    },
  },
  mounted: async function () {
    var fec = new Date();
    //let fec2 = await agregarMeses(fec,6);
    this.MinFec = await formatoFechaVue(fec);
    //this.MaxFec = fec2;

    let fd = new FormData();
    var json = await enviarJsonPOST(
      "creditoConsumo/datos_historico_antecedentes",
      fd
    );
    if (json.error == 0) {
      if (json.error.data) {
        this.sueldoLiquido = json.data.sueldo_liquido;
        this.deudaMensual = json.data.deuda_mensual;
        this.deudaTotal = json.data.deuda_total;
      }
    } else {
      this.validarMensaje(json);
    }
  },
  methods: {
    SetMontoCredito: function(val){
      this.$emit("setValConsumo2", "montoSolicitar", val);
    },
    SetCantidadCuotas: function(val){
      this.$emit("setValConsumo2", "numeroCuotas", val);
    },
    SetFechaCuotas: function(val){
      this.$emit("setValConsumo2", "fechaPrimeraCuota", val);
    },
    SetNombreCredito: function(val){
      this.$emit("setValConsumo2", "nombreCredito", val);
    },
    SetMontoSueldoLiquido: function(val){
      this.$emit("setValConsumo2", "sueldoLiquido", val);
    },
    SetMontoGastoMensual: function(val){
      this.$emit("setValConsumo2", "deudaMensual", val);
    },
    SetMontoDeudaTotal: function(val){
      this.$emit("setValConsumo2", "deudaTotal", val);
    },
    validarMensaje: function (msgAjax) {
      this.$emit("validarMensaje", msgAjax);
    },
  }
};
</script>