<template>
  <v-text-field
    :label="label"
    variant="outlined"
    :autofocus="autofocus"
    ref="Email"
    v-model="valor"
    density="compact"
    autocomplete="false"
    prepend-inner-icon="mdi-email-outline"
    placeholder="nombre@dominio.com"
    hint="Ingrese su correo electrónico"
    :readonly="readonly"
    :loading="validating"
    :disabled="validating"
    :maxLength= "maxLength"
    :rules="[
      this.requerido && rules.requerido ? rules.requerido : null,
      (this.valor != null && this.minLength > this.valor.length) 
        ? 'Mínimo '+ this.separadorMiles(this.minLength)+' digitos.'
        : null,
      (this.valor != null && this.maxLength < this.valor.length) 
        ? 'Máximo '+ this.separadorMiles(this.maxLength)+' digitos.'
        : null,
        rules.formato
    ]"
  ></v-text-field>
</template>

<script>
export default {
  data: () => ({
    valor: null,
    validating: false,
    label: "Email",
    minLength: 8,
    maxLength: 50,
    autofocus: false,
    requerido: false,
    readonly: false,
    rules: {
      requerido(v) {
        if (!v) {
          return "Es requerido.";
        }
      },
      formato(v) {
        if (v) {
          // Expresión regular para validar el correo electrónico
          var regexCorreo = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

          // Validar el correo electrónico con la expresión regular
          if (!regexCorreo.test(v)) {
            return "Correo inválido (nn@dd.cl).";
          }
        }
      },
    },
  }),
  props: {
    valorSet: String,
    validatingSet: Boolean,
    labelSet: String,
    autofocusSet: Boolean,
    requeridoSet: Boolean,
    readonlySet: Boolean,
    minLengthSet: Number,
    maxLengthSet: Number,
    focus: Boolean,
  },
  watch: {
    valorSet: function (val) {
      this.valor = val;
    },
    validatingSet: function (val) {
      this.validating = val;
    },
    labelSet: function (val) {
      this.label = val;
    },
    autofocusSet: function (val) {
      this.autofocus = val;
    },
    requeridoSet: function (val) {
      this.requerido = val;
    },
    readonlySet: function (val) {
      this.readonly = val;
    },
    minLengthSet: function (val) {
      this.minLengthSet = val;
    },
    maxLengthSet: function (val) {
      this.maxLength = val;
    },
    focus: function (val) {
      if (val) {
        this.$refs.Email.focus();
      }
    },
  },
  mounted: function () {
    if (this.valorSet) {
      this.valor = this.valorSet;
    }
    if (this.validatingSet) {
      this.validating = this.validatingSet;
    }
    if (this.labelSet) {
      this.label = this.labelSet;
    }
    if (this.autofocusSet) {
      this.autofocus = this.autofocusSet;
    }
    if (this.requeridoSet) {
      this.requerido = this.requeridoSet;
    }
    if (this.readonlySet) {
      this.readonly = this.readonlySet;
    }
    if (this.minLengthSet) {
      this.minLength = this.minLengthSet;
    }
    if (this.maxLengthSet) {
      this.maxLength = this.maxLengthSet;
    }
  },
  methods: {
    separadorMiles: function (numero) {
      // Validar que el número sea un valor numérico
      if (isNaN(numero)) {
        return "0";
      }
      numero = parseFloat(numero);
      // Usar toLocaleString para formatear el número con separador de miles
      return numero.toLocaleString("es-CL");
    },
  }
};
</script>

<style>
</style>