import { createRouter, createWebHistory } from 'vue-router'
import Start from '@/components/Start/start.vue'
import validMail from '@/components/Cliente/Seguridad/validMail.vue'
import changePassword from '@/components/Cliente/Seguridad/changePassword.vue'
import menuPrincipalCliente from '@/components/Cliente/Principal/menuPrincipal.vue';
import validMailExecutive from '@/components/Banca/Seguridad/validMailExecutive.vue';
import changePasswordExecutive from '@/components/Banca/Seguridad/changePasswordExecutive.vue';
import menuPrincipalBanca from '@/components/Banca/principal/menuPrincipal.vue';




const routes = [
    {
        path: '/',
        name: 'Start',
        component: Start
    },
    {
        path: '/validMailCli/:email/:codigo',
        name: 'validMailCliMailCod',
        component: validMail
    },
    {
        path: '/validMailCli/:email',
        name: 'validMailCliMail',
        component: validMail
    },
    {
        path: '/validMailCli',
        name: 'validMailCli',
        component: validMail
    },
    {
        path: '/changePasswordCli/:email/:codigo',
        name: 'changePasswordCliCod',
        component: changePassword
    },
    {
        path: '/changePasswordCli/:email',
        name: 'changePasswordCliMail',
        component: changePassword
    },
    {
        path: '/changePasswordCli',
        name: 'changePasswordCli',
        component: changePassword
    },
    {
        path: '/cliente',
        name: 'cliente',
        component: menuPrincipalCliente
    },
    {
        path: '/validMailExec/:email/:codigo',
        name: 'validMailExecMailCod',
        component: validMailExecutive
    },
    {
        path: '/validMailExec/:email',
        name: 'validMailExecMail',
        component: validMailExecutive
    },
    {
        path: '/validMailExec',
        name: 'validMailExec',
        component: validMailExecutive
    },
    {
        path: '/changePasswordExec/:email/:codigo',
        name: 'changePasswordExecCod',
        component: changePasswordExecutive
    },
    {
        path: '/changePasswordExec/:email',
        name: 'changePasswordExecMail',
        component: changePasswordExecutive
    },
    {
        path: '/changePasswordExec',
        name: 'changePasswordExec',
        component: changePasswordExecutive
    },
    {
        path: '/banca',
        name: 'banca',
        component: menuPrincipalBanca
    },

    {
        path: '/banca/:cod',
        name: 'bancaCod',
        component: menuPrincipalBanca
    },


]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router