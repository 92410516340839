<template>
  <v-dialog
    v-model="dialogOn"
    transition="dialog-top-transition"
    fullscreen
    persistent
  >
    <v-card>
      <v-toolbar
        :style="
          'background-color:' +
          paletColor.fondoPincipal +
          ';color: ' +
          paletColor.letra2 +
          ';'
        "
      >
        <v-btn icon="mdi-close" @click="cerrar"></v-btn>

        <v-toolbar-title>
          <span class="text-h5 display-1 font-weight-bold"
            >Ofertas recibidas</span
          >
        </v-toolbar-title>

        <v-spacer></v-spacer>
      </v-toolbar>
      <v-container class="bg-blue-lighten-5 mt-2">
        <div class="text-h5">Ofertas recibidas</div>
        <div class="text-caption"></div>
        <v-row>
          <v-col cols="12" align="center" justify="center">
            <v-row class="mt-10 ml-5 mr-5 mb-5">
              <v-col cols="12">
                <span class="text-blue-darken-4 font-weight-black">{{
                  user.name
                }}</span
                >, estos son las ofertas que tienes para el crédito
                <b>{{ nombre_credito }}</b> por
                <b>{{ formatoMonedaChile(monto_credito) }}</b> para
                {{
                  descripcion_credito && descripcion_credito != "null"
                    ? descripcion_credito
                    : '"Sin descripción."'
                }}.<br />
                Sólo requiere hacer clic sobre la opción que escoja.
              </v-col>
              <v-col cols="12" md="6" v-for="oferta in ofertas" :key="oferta">
                <ofertaCredito
                  :oferta="oferta"
                  @cargarInformacion="carcargInformacionActualizada"
                  :user="user"
                  :usuarioId="usuarioId"
                  :ejecutivo="ejecutivo"
                  :ejecutivoId="ejecutivoId"
                  @validarMensaje="validarMensaje"
                  @setEjecutivo="setEjecutivo"
                  @setUsuario="setUsuario"
                  @setFullLoading="setFullLoading"
                  @SalirSistema="SalirSistema"
                ></ofertaCredito>
              </v-col>
            </v-row>
            <volver labelSet="Volver" @accion="cerrar"></volver>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  paletColor,
  obtenerJsonGET,
  formatoMonedaChile,
} from "@/helpers/funcionesEstandard";
import ofertaCredito from "@/components/Usuario/creditoConsumo/ofertaCredito.vue";
import volver from "@/components/form/buttonCancelform.vue";

export default {
  data: () => ({
    dialogOn: false,
    ofertas: [],
    nombre_credito: null,
    monto_credito: null,
    descripcion_credito: null,
    formatoMonedaChile: formatoMonedaChile,
    paletColor: paletColor,
  }),
  props: {
    user: Object,
    usuarioId: Number,
    dialog: Boolean,
    idSolicitud: Number,
  },
  watch: {
    dialog: function (val) {
      this.dialogOn = val;
      if (val) {
        this.cargarInformacion();
      }
    },
  },
  components: {
    ofertaCredito,
    volver,
  },
  view: {},
  mounted: async function () {},

  methods: {
    cerrar: function () {
      this.$emit("cerrarListaOfertas");
    },
    carcargInformacionActualizada: async function () {
      this.cargarInformacion();
      this.$emit("cargarSolicitudes");
    },
    cargarInformacion: async function () {
      this.setFullLoading(true, "Cargando Ofertas");
      console.log("uno");
      if (!this.user) {
        this.$router.push("/");
      } else if (this.idSolicitud) {
        console.log("dos");
        if (!this.user.token) {
          this.$router.push("/");
        } else {
          console.log("tres");
          console.log(this.dialog);
          var SendData = {
            usuarioId: this.user.usuarioId,
            idSolicitud: this.idSolicitud,
          };
          var resp = await obtenerJsonGET(
            "principal/obtener_ofertas_solicitudes",
            SendData
          );
          console.log(resp);
          if (resp.error == 0) {
            this.ofertas = resp.data;
            this.nombre_credito = resp.data[0].nombre_credito;
            this.monto_credito = resp.data[0].monto_credito;
            this.descripcion_credito = resp.data[0].descripcion_credito;
          } else {
            this.validarMensaje(resp);
          }
        }
      } else {
        this.cerrar();
      }
      this.setFullLoading(false, null);
    },
    volver: function () {
      this.$router.push("/menuprincipal");
    },
    validarMensaje: function (msgAjax) {
      this.$emit("validarMensaje", msgAjax);
    },
    setEjecutivo: function (usuario) {
      this.$emit("setEjecutivo", usuario);
    },
    setUsuario: function (usuario) {
      this.$emit("setUsuario", usuario);
    },
    setFullLoading: function (visible, texto) {
      this.$emit("setFullLoading", visible, texto);
    },
    SalirSistema: function () {
      this.$emit("SalirSistema");
    },
  },
};
</script>

<style>
</style>
