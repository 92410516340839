<template>
  <v-text-field
    :label="label"
    variant="outlined"
    :autofocus="autofocus"
    ref="RUT"
    v-model="valor"
    density="compact"
    autocomplete="false"
    prepend-inner-icon="mdi-card-account-details-outline"
    placeholder="99999999-K"
    hint="Ingrese su RUT"
    :readonly="readonly"
    :loading="validating"
    :disabled="validating"
    :maxLength="maxLength"
    :rules="[
      this.requerido && rules.requerido ? rules.requerido : null,
      rules.minimo,
      rules.formato,
    ]"
  ></v-text-field>
</template>
  
  <script>
export default {
  data: () => ({
    valor: null,
    validating: false,
    label: "RUT",
    autofocus: false,
    requerido: false,
    readonly: false,
    minLength: 0,
    maxLength: 11,
    rules: {
      requerido(v) {
        if (!v) {
          return "Es requerido.";
        }
      },
      minimo(v) {
        if (v) {
          if (v.length < 9) {
            return "Debe tener mas de 9 digitos.";
          }
        }
      },
      formato(v) {
        if (v) {
          v = v.replace(/\./g, "").replace(/\-/g, "");
          v = v.replace(/\-/g, "");

          // Separar número y dígito verificador
          var rutNumero = v.slice(0, -1);
          var rutDV = v.slice(-1).toUpperCase();

          // Validar que el número tenga al menos 7 dígitos
          if (rutNumero.length < 6) {
            return 'Debe tener al menos 6 dígitos';
          }

          // Calcular el dígito verificador esperado
          var suma = 0;
          var multiplo = 2;
          for (var i = rutNumero.length - 1; i >= 0; i--) {
            suma += parseInt(rutNumero.charAt(i)) * multiplo;
            multiplo = multiplo < 7 ? multiplo + 1 : 2;
          }
          var dvEsperado = 11 - (suma % 11);
          dvEsperado =
            dvEsperado === 11
              ? "0"
              : dvEsperado === 10
              ? "K"
              : dvEsperado.toString();

          // Comparar el dígito verificador calculado con el ingresado
          if(rutDV != dvEsperado){
            return 'RUT Inválido!.';
          }
        }
      },
    },
  }),
  props: {
    valorSet: String,
    validatingSet: Boolean,
    labelSet: String,
    autofocusSet: Boolean,
    requeridoSet: Boolean,
    readonlySet: Boolean,
    minLengthSet: Number,
    maxLengthSet: Number,
    focus: Boolean,
  },
  watch: {
    valorSet: function (val) {
      this.valor = val;
    },
    validatingSet: function (val) {
      this.validating = val;
    },
    labelSet: function (val) {
      this.label = val;
    },
    autofocusSet: function (val) {
      this.autofocus = val;
    },
    requeridoSet: function (val) {
      this.requerido = val;
    },
    readonlySet: function (val) {
      this.readonly = val;
    },
    minLengthSet: function (val) {
      this.minLengthSet = val;
    },
    maxLengthSet: function (val) {
      this.maxLength = val;
    },
    focus: function (val) {
      if (val) {
        this.$refs.Email.focus();
      }
    },
  },
  mounted: function () {
    if (this.valorSet) {
      this.valor = this.valorSet;
    }
    if (this.validatingSet) {
      this.validating = this.validatingSet;
    }
    if (this.labelSet) {
      this.label = this.labelSet;
    }
    if (this.autofocusSet) {
      this.autofocus = this.autofocusSet;
    }
    if (this.requeridoSet) {
      this.requerido = this.requeridoSet;
    }
    if (this.readonlySet) {
      this.readonly = this.readonlySet;
    }
    if (this.minLengthSet) {
      this.minLength = this.minLengthSet;
    }
    if (this.maxLengthSet) {
      this.maxLength = this.maxLengthSet;
    }
  },
  methods: {},
};
</script>
  
  <style>
</style>