<template>
  <v-card class="sticky-card ma-6 elevation-6" color="white" v-if="muestra()">
    <v-card-title class="headline" style="color: black">
      <div class="text-h6">¡ Confirmación capacidad pago !</div>
    </v-card-title>
    <!--v-card-subtitle class="subtitle-1" style="color: black">
          Subtítulo de la Tarjeta
        </v-card-subtitle-->
    <v-card-text>
      <v-form
        ref="formConf"
        v-model="validConf"
        @submit.prevent="confirmarPago"
      >
        <v-row>
          <v-col cols="12" md="8">
            <div class="text-caption">
              Es necesario validar que usted tiene capacidad de pago, para esto
              se solciitará realizar un pago de $100 mediante medio de pago
              seguro
            </div>
          </v-col>
          <v-col cols="12" md="4" align="center" justify="center  ">
            <buttonSubmitformVue
              labelSet="Confirmar"
              :validating="validating"
            />
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>
  
  <script>
  import {paletColor,obtenerJsonGET} from '@/helpers/funcionesEstandard';
import buttonSubmitformVue from "@/components/form/buttonSubmitform.vue";
import { createLogger } from 'vuex';

export default {
  name: "StickyCard",
  validating: false,
  codParam: null,
  data: () => ({
    validConf: null,
    paletColor: paletColor,
  }),
  props: {
  	user: Object,
  	usuarioId: Number,
  	ejecutivo: Object,
  	ejecutivoId: Number,
  },
  components: {
    buttonSubmitformVue,
  },
  mounted: async function(){
    if(this.$route.params.cod){
      if(typeof this.$route.params.cod == 'string'){
        this.codParam = this.$route.params.cod;
        this.setFullLoading(true, "Confirmnando pago");
        var SendData = {
          usuarioId: this.ejecutivo.usuarioId,
          codigo: this.codParam
        };
        this.codParam = null;
        var resp = await obtenerJsonGET(
          "usuarios/confirmacion_pago_inicial_ejecutivo_codigo",
          SendData
        );
        if (resp.error == 0) {
          this.setEjecutivo(resp.data);
        } else {
          this.validarMensaje(resp);
        }
        this.setFullLoading(false, null);
        this.$router.push("/banca/");
      }
    }
  },
  methods: {
    muestra: function () {
      let r = parseInt(this.ejecutivo.empresa_persona_estado_id) == 300;
      return r;
    },
    confirmarPago: async function () {
      this.setFullLoading(true, "Enviando página segura de pago");
      this.validating = true;
      var SendData = {
        usuarioId: this.ejecutivo.usuarioId,
      };
      var resp = await obtenerJsonGET(
        "Serviciosexternos/confirmacion_pago_inicial_ejecutivo",
        SendData
      );
      this.setFullLoading(false, null);
      this.validating = false;
      if (resp.error == 0) {
        window.location.href = resp.data;
      } else {
        this.validarMensaje(resp);
      }
    },
    validarMensaje: function (msgAjax) {
      this.$emit("validarMensaje", msgAjax);
    },
    setEjecutivo: function (usuario) {
      this.$emit("setEjecutivo", usuario);
    },
    setUsuario: function (usuario) {
      this.$emit("setUsuario", usuario);
    },
    setFullLoading: function (visible, texto) {
      this.$emit("setFullLoading", visible, texto);
    },
    SalirSistema: function () {
      this.$emit("SalirSistema");
    },
  },
};
</script>
  
  <style scoped>
.sticky-card {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 150px;
  z-index: 1000; /* Asegúrate de que la tarjeta esté sobre otros elementos */
}
</style>
  