<template>
  <v-dialog
    v-model="dialogIn"
    transition="dialog-top-transition"
    fullscreen
    persistent
  >
    <v-card>
      <v-toolbar
        :style="
          'background-color:' +
          paletColor.fondoPincipal +
          ';color: ' +
          paletColor.letra2 +
          ';'
        "
      >
        <v-btn icon="mdi-close" @click="cerrarAccion"></v-btn>

        <v-toolbar-title>
          <span class="text-h5 display-1 font-weight-bold"
            >Nuevo crédito de consumo</span
          >
        </v-toolbar-title>

        <v-spacer></v-spacer>
      </v-toolbar>

      <v-container class="bg-blue-lighten-5 mt-2">
        <div class="text-h5">Nuevo crédito de consumo</div>
        <div class="text-caption">
          <v-form ref="formNew" v-model="validForm" @submit.prevent="submitNew">
            <h3 class="text-center">Solicitud de Crédito de consumo</h3>
            <baseNuevoCredito
              @setValConsumo2="setValConsumo2"
              :validating="validating"
              :user="user"
              :usuarioId="usuarioId"
              :ejecutivo="ejecutivo"
              :ejecutivoId="ejecutivoId"
              :heightWindow="widthWindow"
              :widthWindow="widthWindow"
              :isMobile="isMobile"
              @validarMensaje="validarMensaje"
              @setEjecutivo="setEjecutivo"
              @setUsuario="setUsuario"
              @setFullLoading="setFullLoading"
              @SalirSistema="SalirSistema"
            />
            <div class="text-center text-caption">
              Registra que necesitas y los ejecutivos competirán por ofrecerte
              las mejores opciones.
              <br />Tú eliges las condiciones que más te convengan. ¡Tú decides!
            </div>
            <v-row>
              <v-col cols="12" md="6">
                <formbtnsend
                  labelSet="Registrar"
                  :validating="validating"
                ></formbtnsend>
              </v-col>
              <v-col cols="12" md="6">
                <buttonCancelformVue
                  labelSet="Calcelar"
                  :validating="validating"
                  @cerrar="cerrarAccion"
                ></buttonCancelformVue>
              </v-col>
            </v-row>
          </v-form>
        </div>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import { paletColor, enviarJsonPOST } from "@/helpers/funcionesEstandard";
import baseNuevoCredito from "./baseNuevoCredito.vue";
import formbtnsend from "@/components/form/buttonSubmitform.vue";
import buttonCancelformVue from "@/components/form/buttonCancelform.vue";

export default {
  data: () => ({
    dialogIn: false,
    paletColor: paletColor,
    validForm: false,
    validating: false,
    nombreCredito: null,
    deudaTotal: null,
    deudaMensual: null,
    sueldoLiquido: null,
    montoSolicitar: null,
    numeroCuotas: null,
    fechaPrimeraCuota: null,
    sd: null,
    ci: null,
    it: null,
  }),
  props: {
    dialog: Boolean,
    user: Object,
    usuarioId: Number,
    ejecutivo: Object,
    ejecutivoId: Number,
    heightWindow: Number,
    widthWindow: Number,
    isMobile: Boolean,
  },
  watch: {
    dialog: function (val) {
      this.dialogIn = val;
    },
  },
  components: {
    baseNuevoCredito,
    formbtnsend,
    buttonCancelformVue,
  },
  methods: {
    submitNew: async function () {
      const { valid } = await this.$refs.formNew.validate();
      if (valid) {
        let fd = new FormData();
        fd.append("nombreCredito", this.nombreCredito);
        fd.append("descripcionCredito", this.descripcionCredito);
        fd.append("deudaTotal", this.deudaTotal);
        fd.append("deudaMensual", this.deudaMensual);
        fd.append("sueldoLiquido", this.sueldoLiquido);
        fd.append("montoSolicitar", this.montoSolicitar);
        fd.append("numeroCuotas", this.numeroCuotas);
        fd.append("fechaPrimeraCuota", this.fechaPrimeraCuota);
        fd.append("sd", this.sd);
        fd.append("ci", this.ci);
        fd.append("it", this.it);
        var json = await enviarJsonPOST(
          "creditoConsumo/guardar_nuevo_consumo",
          fd
        );

        if(json.error == 0){
          this.cerrarAccion();
          this.$emit("obtenerSolicitudes");
        }else{
          this.validarMensaje(json);
        }
      }
    },
    setValConsumo2: function (clave, valor) {
      this[clave] = valor;
    },
    cerrarAccion: function () {
      this.$emit("cerrarAccion", "cerrarNuevoConsumo");
    },
    validarMensaje: function (msgAjax) {
      this.$emit("validarMensaje", msgAjax);
    },
    setEjecutivo: function (usuario) {
      this.$emit("setEjecutivo", usuario);
    },
    setUsuario: function (usuario) {
      this.$emit("setUsuario", usuario);
    },
    setFullLoading: function (visible, texto) {
      this.$emit("setFullLoading", visible, texto);
    },
    SalirSistema: function () {
      this.$emit("SalirSistema");
    },
  },
};
</script>

<style>
</style>