<template>
  <v-card
    class="sticky-card ma-6 elevation-6 bg-light-green-lighten-4"
    v-if="muestra()"
  >
    <v-card-title style="color: black">
      <div class="text-h6">
        ¡ Confirmación de correo electrónico institucional !
      </div>
    </v-card-title>
    <v-card-text>
      <v-form
        ref="formNew"
        v-model="validForm"
        @submit.prevent="solicitarCodigo"
      >
        <v-row>
          <v-col cols="12" md="8">
            <div class="text-caption">
              Este correo no será utilizado, pero para dar seriedad, necesitamos
              corroborar este prier paso que corresponde a que ustetiene al
              menos correo oficial de la institución.
            </div>
            <emailVue
              labelSet="Email Institucional"
              :requeridoSet="true"
              ref="email"
              :validating="validating"
            />
          </v-col>
          <v-col cols="12" md="4" align="center" justify="center  ">
            <buttonSubmitformVue
              labelSet="Enviar código"
              :validating="validating"
            />
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
  <v-card
    class="sticky-card ma-6 elevation-6 bg-light-green-lighten-4"
    v-if="muestra2()"
  >
    <v-card-title style="color: black">
      <div class="text-h6">¡ Confirmación de correo !</div>
    </v-card-title>
    <v-card-text>
      <v-form
        ref="formConf"
        v-model="validConf"
        @submit.prevent="enviarValidacion"
      >
        <v-row>
          <v-col cols="12" md="8">
            <div class="text-caption">
              Por favor ingrese el código que fue enviado a su correo
              institucional.
            </div>
            <v-otp-input
              length="7"
              ref="opt"
              v-model="opt"
              :rules="[rulesOPT.requerido]"
            ></v-otp-input>
          </v-col>
          <v-col cols="12" md="4" align="center" justify="center  ">
            <v-row>
              <v-col cols="12">
                <buttonCancelformVue
                  labelSet="Volver a solicitar"
                  @accion="volveraSolicitar"
                />
              </v-col>
              <v-col cols="12">
                <buttonSubmitformVue
                  labelSet="Confirmar"
                  :validating="validating"
                />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { paletColor, obtenerJsonGET } from "@/helpers/funcionesEstandard";
import emailVue from "@/components/form/email.vue";
import buttonCancelformVue from "@/components/form/buttonCancelform.vue";
import buttonSubmitformVue from "@/components/form/buttonSubmitform.vue";
export default {
  data: () => ({
    validForm: null,
    validating: false,
    opt: null,
    mail: null,
    validConf: null,
    paletColor: paletColor,
    rulesOPT: {
      requerido: (value) => !!value || "El código es requerido",
      minChar: (value) =>
        (value && value.length == 7) ||
        "Debe tener al menos 10 dígitos (aaa@aaa.cl)",
    },
    eje: {},
  }),
  props: {
  	user: Object,
  	usuarioId: Number,
  	ejecutivo: Object,
  	ejecutivoId: Number,
  },
  components: {
    emailVue,
    buttonCancelformVue,
    buttonSubmitformVue,
  },
  mounted: function () {
    this.eje = {};
    this.eje.empresa_persona_estado_id =
      this.ejecutivo.empresa_persona_estado_id;
    this.eje.estado_usuario = this.ejecutivo.estado_usuario;
    this.eje.nombre = this.ejecutivo.name;
    this.eje.token = this.ejecutivo.token;
    this.eje.id = this.ejecutivo.usuarioId;
  },
  methods: {
    muestra: function () {
      let r = parseInt(this.ejecutivo.empresa_persona_estado_id) == 100;
      return r;
    },
    muestra2: function () {
      let r = parseInt(this.ejecutivo.empresa_persona_estado_id) == 200;
      return r;
    },
    solicitarCodigo: async function () {
      const { valid } = await this.$refs.formNew.validate();
      if (valid) {
        this.setFullLoading(true, "Solicitando codigo de validación");
        this.validating = true;
        var SendData = {
          usuarioId: this.ejecutivo.usuarioId,
          email: this.$refs.email.valor,
        };
        var resp = await obtenerJsonGET(
          "usuarios/solicitar_codigo_correo_institucional",
          SendData
        );
        if (resp.error == 0) {
          this.setEjecutivo(resp.data);
        } else {
          this.validarMensaje(resp);
        }
        this.setFullLoading(false, null);
      }
      this.validating = false;
    },
    volveraSolicitar: function () {
      this.eje.empresa_persona_estado_id = 100;
      this.setEjecutivo(this.eje);
    },
    enviarValidacion: async function () {
      const { valid } = await this.$refs.formConf.validate();
      if (valid && this.opt) {
        this.setFullLoading(true, "Solicitando codigo de validación");
        this.validating = true;
        var SendData = {
          usuarioId: this.ejecutivo.usuarioId,
          opt: this.opt,
        };
        var resp = await obtenerJsonGET(
          "usuarios/validar_codigo_correo_institucional",
          SendData
        );
        if (resp.error == 0) {
          this.setEjecutivo(resp.data);
        } else {
          this.validarMensaje(resp);
        }
        this.setFullLoading(false, null);
      }else{
        let msg={
          error: 1,
          message: 'Debe ingresar el código',
        }
        this.validarMensaje(msg);
      }
      this.validating = false;
    },
    validarMensaje: function (msgAjax) {
      this.$emit("validarMensaje", msgAjax);
    },
    setEjecutivo: function (usuario) {
      this.$emit("setEjecutivo", usuario);
    },
    setUsuario: function (usuario) {
      this.$emit("setUsuario", usuario);
    },
    setFullLoading: function (visible, texto) {
      this.$emit("setFullLoading", visible, texto);
    },
    SalirSistema: function () {
      this.$emit("SalirSistema");
    },
  },
};
</script>

<style scoped>
.sticky-card {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 150px;
  z-index: 1000; /* Asegúrate de que la tarjeta esté sobre otros elementos */
}
</style>

