<template>
  <v-text-field
    ref="cantidadCuotas"
    :autofocus="autofocus"
    :label="label"
    v-model="valor"
    variant="outlined"
    density="compact"
    autocomplete="false"
    type="number"
    :loading="validating"
    :disabled="validating"
    prepend-inner-icon="mdi-calendar-clock"
    :placeholder="textoAlternativo"
    :hint="MensajeAyuda"
    :rules="[
      rulesCuotasConsumo.requerido,
      rulesCuotasConsumo.minChar,
      rulesCuotasConsumo.maxChar,
      rulesCuotasConsumo.minVal,
      rulesCuotasConsumo.maxVal,
    ]"
  >
  </v-text-field>
</template>
  
  <script>
import { rulesCuotasConsumo } from "@/helpers/funcionesEstandard";
import { createLogger } from "vuex";

export default {
  data: () => ({
    valor: null,
    label: "Número de cuotas",
    textoAlternativo: "99",
    MensajeAyuda: "Ingrese la cantidad e cuotas que requiere pagar",
    validating: null,
    autofocus: false,
    rulesCuotasConsumo: rulesCuotasConsumo,
  }),
  watch: {
    valorSet: function (val) {
      this.valor = val;
    },
    labelSet: function (val) {
      this.label = val;
    },
    validatingSet: function (val) {
      this.validating = val;
    },
    textoAlternativoSet: function (val) {
      this.textoAlternativo = val;
    },
    MensajeAyudaSet: function (val) {
      this.textoAlternativo = val;
    },
    autofocusSet: function (val) {
      if (val) {
        this.$refs.cantidadCuotas.focus();
      }
    },
        valor: function(val){
            this.$emit("SetValor",val);
        }
  },
  props: {
    valorSet: Number,
    labelSet: String,
    validatingSet: Boolean,
    autofocusSet: Boolean,
    textoAlternativoSet: String,
    MensajeAyudaSet: String,
  },
  mounted: async function () {
    if (this.valorSet) {
      this.valor = this.valorSet;
      this.valor = this.valor.replaceAll("$", "");
      this.valor = this.valor.replaceAll(".", "");
    }
    if (this.labelSet) {
      this.label = this.labelSet;
    }
    if (this.validatingSet) {
      this.validating = this.validatingSet;
    }
    if (this.autofocusSet) {
      this.autofocus = this.autofocusSet;
    }
    if (this.textoAlternativoSet) {
      this.textoAlternativo = this.textoAlternativoSet;
    }
    if (this.MensajeAyudaSet) {
      this.MensajeAyuda = this.MensajeAyudaSet;
    }
  },
};
</script>
  
  <style>
</style>