<template>
  <v-dialog
    persistent
    max-width="70%"
    v-if="dialogOfertar"
    v-model="dialogOfertar"
  >
    <v-form ref="formNew" v-model="validForm" @submit.prevent="submitNew">
      <v-card class="elevation-6 mt-10">
        <v-card-title> Nueva oferta de crédito</v-card-title>
        <v-card-text>
          <v-row align="center" justify="center">
            <v-col cols="12" lg="4" class="border-col">   
              <valorNumerico
                ref="montoOfertado"
                :valorSet="montoSolicitadoSol"
                labelSet="Monto ofertado"
                :validatingSet="validating"
                :autofocusSet="true"
                 textoAlternativoSet="99999999"
                 MensajeAyudaSet="Entre $500.000 y $99.999.999"
                 preIconoSet="mdi-currency-usd"
                 :requeridoSet="true"
                 valorMaximoSet="99999999"
                 valorMinimoSet="500000"
              ></valorNumerico>
            </v-col>
            <v-col cols="12" lg="4" class="border-col">   
              <valorNumerico
                ref="montoCuota"
                :valorSet="montoCuota"
                labelSet="Monto cuota"
                :validatingSet="validating"
                :autofocusSet="false"
                 textoAlternativoSet="99999999"
                 MensajeAyudaSet="Entre $15.000 y $99.000.000"
                 preIconoSet="mdi-currency-usd"
                 :requeridoSet="true"
                 valorMaximoSet="99999999"
                 valorMinimoSet="15000"
              ></valorNumerico>
            </v-col>
            <v-col cols="12" lg="4" class="border-col">   
              <fecha
                ref="fechaPrimeraCuota"
                :valorSet="fecPrimCuo"
                labelSet="Fecha primera cuota"
                :validatingSet="validating"
                :autofocusSet="false"
                :minFecSet="fecActual"
                :maxFecSet="fecMax"
               MensajeAyudaSet="Fecha de la primera cuota que deberá pagar"
               requeridoSet="true"
              ></fecha>
            </v-col>
            <v-col cols="12" lg="4" class="border-col">   
              <valorNumerico
                ref="numeroCuotas"
                :valorSet="numCuo"
                labelSet="Cantidad de cuotas"
                :validatingSet="validating"
                :autofocusSet="false"
                 textoAlternativoSet="99"
                 MensajeAyudaSet="Entre 6 y 72"
                 preIconoSet="mdi-sigma"
                 :requeridoSet="true"
                 valorMaximoSet="72"
                 valorMinimoSet="6"
              ></valorNumerico>
            </v-col>
            <v-col cols="12" lg="4" class="border-col">   
              <valorNumerico
                ref="tasaMensual"
                :valorSet="tasaMensual"
                labelSet="Tasa Mensual"
                :validatingSet="validating"
                :autofocusSet="false"
                 textoAlternativoSet="99.99"
                 MensajeAyudaSet="Entre 0 y 100"
                 posIconSet="mdi-percent-outline"
                 :requeridoSet="true"
                 valorMaximoSet="100"
                 valorMinimoSet="0"
              ></valorNumerico>
            </v-col>
            <v-col cols="12" lg="4" class="border-col">   
              <valorNumerico
                ref="cae"
                :valorSet="cae"
                labelSet="CAE"
                :validatingSet="validating"
                :autofocusSet="false"
                 textoAlternativoSet="99.99"
                 MensajeAyudaSet="Entre 0 y 100"
                 posIconSet="mdi-percent-outline"
                 :requeridoSet="true"
                 valorMaximoSet="100"
                 valorMinimoSet="0"
              ></valorNumerico>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-row>
            <v-col cols="6" align="center">
              <buttonSubmitform :validating="validating"></buttonSubmitform>
            </v-col>
            <v-col cols="6" align="center">
              <buttonCancelform :validating="validating" @cerrar="cerrarOferta"></buttonCancelform>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import {
  paletColor,
  rulesMontoCredConsumo,
  rulesCuotasConsumo,
  formatoFechaVue,
  enviarJsonPOST,
} from "@/helpers/funcionesEstandard";

import valorNumerico from "@/components/form/numero.vue";
import buttonSubmitform from "@/components/form/buttonSubmitform.vue";
import buttonCancelform from "@/components/form/buttonCancelform.vue";
import fecha from "@/components/form/fecha.vue";

export default {
  data: () => ({
    paletColor: paletColor,
    dialogOfertar: null,
    validForm: null,
    validating: null,
    montoOfrecido: null,
    montoCuota: null,
    numeroCuotas: null,
    tasaMensual: null,
    cae: null,
    fechaPrimeraCuota: null,
    fecActual: new Date(),
    fecMax: null,
    MinFec: null,

    rulesMontoCredConsumo: rulesMontoCredConsumo,
    rulesCuotasConsumo: rulesCuotasConsumo,
  }),
  watch: {
    dialogOferta: function (val) {
      this.dialogOfertar = val;
    },
    montoSolicitadoSol: function (val) {
      this.montoOfrecido = val;
    },
    fecPrimCuo: function (val) {
      this.fechaPrimeraCuota = val;
    },
    numCuo: function (val) {
      this.numeroCuotas = val;
    },
  },
  props: {
    dialogOferta: Boolean,
    montoSolicitadoSol: Number,
    fecPrimCuo: String,
    numCuo: Number,
    solCred: Number,
    paginaRemote: Number,
  	user: Object,
  	usuarioId: Number,
  	ejecutivo: Object,
  	ejecutivoId: Number,
  },
  components: {
    valorNumerico,
    buttonSubmitform,
    buttonCancelform,
    fecha,
  },
  mounted: function(){
    this.fecMax = new Date();
    this.fecMax.setMonth(this.fecMax.getMonth() + 12);
    if (this.fecMax.getDate() !== this.fecMax.getDate()) {
      this.fecMax.setDate(0);
    }
  },
  methods: {
    submitNew: async function () {
      this.validating = true;
      const { valid } = await this.$refs.formNew.validate();
      if (valid) {
        let fd = new FormData();
        fd.append("solCred", this.solCred);
        fd.append("montoOfertado", this.$refs.montoOfertado.valor);
        fd.append("montoCuota", this.$refs.montoCuota.valor);
        fd.append("fechaPrimeraCuota", this.$refs.fechaPrimeraCuota.valor);
        fd.append("numeroCuotas", this.$refs.numeroCuotas.valor);
        fd.append("tasaMensual", this.$refs.tasaMensual.valor);
        fd.append("CAE", this.$refs.cae.valor);
        var json = await enviarJsonPOST("creditoConsumo/guardar_nueva_oferta_consumo", fd);
        if(json.error == 0){
          this.cerrarOferta();
          await this.$emit("loadItems", this.paginaRemote);
        }else{
            await this.$emit("validarMensaje", json);
        }
      }
      this.validating = false;
    },
    cerrarOferta: function (id) {
      this.$emit("actionDialogOferta", false, id);
    },
    validarMensaje: function (msgAjax) {
      this.$emit("validarMensaje", msgAjax);
    },
    setEjecutivo: function (usuario) {
      this.$emit("setEjecutivo", usuario);
    },
    setUsuario: function (usuario) {
      this.$emit("setUsuario", usuario);
    },
    setFullLoading: function (visible, texto) {
      this.$emit("setFullLoading", visible, texto);
    },
    SalirSistema: function () {
      this.$emit("SalirSistema");
    },
  },
};
</script>

<style>
</style>