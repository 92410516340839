<template>
  <v-card-text class="mt-12 border-s-lg">
    <v-form
      ref="formChange"
      v-model="formChange.validForm"
      @submit.prevent="submitOPT"
    >
      <h3 class="text-center">Cambio de clave</h3>
      <div class="text-center text-caption">
        Fue enviado un correo electrónico a
        <span class="text-blue">{{ mail }}</span>
        <br />por favor revise su correo electrónico use la clave temporal
        enviada <br />con clave, usted podrá ingresar su clave personalizade y
        usar privadamente los privilegios del sitio.
      </div>
      <v-row align="center" justify="center">
        <v-col cols="12" sm="8" class="mt-8">
          <v-row align="center" justify="center">
            <v-col cols="12" sm="8" class="mt-5 border-col">
              <passwordVue
                :autofocusSet="focusPasswordOld"
                :valorSet="passWordOld"
                labelSet="Clave entregada"
                :requeridoSet="true"
                :validatingSet="formChange.validating"
                ref="formChangePassword"
                :maxLengthSet="20"
                MensajeAyudaSet="Ingrese clave entregada por mail"
              ></passwordVue>
            </v-col>
            <v-col cols="12" sm="8" class="mt-1 border-col">
              <passwordVue
                :autofocusSet="focusPasswordNew"
                labelSet="Clave Nueva"
                :requeridoSet="true"
                :validatingSet="formChange.validating"
                ref="formChangepasswordNew"
                :maxLengthSet="20"
                MensajeAyudaSet="Ingrese su nueva clave."
              ></passwordVue>
            </v-col>
            <v-col cols="12" sm="8" class="mt-1 border-col">
              <passwordVue
                :autofocusSet="false"
                labelSet="Repetir clave Nueva"
                :requeridoSet="true"
                :validatingSet="formChange.validating"
                ref="formChangepasswordReNew"
                :maxLengthSet="20"
                MensajeAyudaSet="Ingrese repita su nueva clave."
              ></passwordVue>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" md="6">
          <buttonSubmitformVue
            labelSet="Cambiar Clave"
            :validating="formChange.validating"
          ></buttonSubmitformVue>
        </v-col>
        <v-col cols="12" md="6">
          <buttonCancelformVue
            labelSet="Cancelar"
            :validating="formChange.validating"
            @cerrar="cerrar"
          ></buttonCancelformVue>
        </v-col>

      </v-row>
    </v-form>
  </v-card-text>
</template>

<script>
import { paletColor,enviarJsonPOST } from "@/helpers/funcionesEstandard";
import buttonCancelformVue from "@/components/form/buttonCancelform.vue";
import buttonSubmitformVue from "@/components/form/buttonSubmitform.vue";
import passwordVue from "@/components/form/password.vue";

export default {
  data: () => ({
    paletColor: paletColor,
    mail: null,
    passWordOld: null,
    focusPasswordOld: false,
    focusPasswordNew: true,
    formChange: {
      validForm: null,
      loading: null,
      validating: null,
    },
    rulesOPT: {
      requerido: (value) => !!value || "El código es requerido",
      minChar: (value) =>
        (value && value.length == 6) ||
        "Debe tener al menos 10 dígitos (aaa@aaa.cl)",
    },
  }),
  components: {
    buttonCancelformVue,
    buttonSubmitformVue,
    passwordVue,
  },
  mounted: function () {
    if (sessionStorage.getItem("emailValid")) {
      this.mail = sessionStorage.getItem("emailValid");
      sessionStorage.removeItem("emailValid");
    } else if (this.$route.params) {
      if (this.$route.params.email && this.$route.params.codigo) {
        this.mail = this.$route.params.email;
        this.passWordOld = this.$route.params.codigo;
        this.focusPasswordOld = false;
        this.focusPasswordNew = true;
      } else if (this.$route.params.email) {
        this.mail = this.$route.params.email;
        this.focusPasswordOld = true;
        this.focusPasswordNew = false;
      }
    } else {
      this.mail = null;
    }
    if (this.mail) {
      this.$router.push("/changePasswordCli/");
    } else {
      this.$router.push("/");
    }
  },
  methods: {
    submitOPT: async function () {
      this.formChange.validating = true;
      const { valid } = await this.$refs.formChange.validate();
      if (valid) {
        if (
          this.$refs.formChangepasswordNew.valor ==
          this.$refs.formChangepasswordReNew.valor
        ) {
          let fd = new FormData();
          fd.append("email", this.mail);
          fd.append("passwordOld", this.$refs.formChangePassword.valor);
          fd.append("passwordNew", this.$refs.formChangepasswordNew.valor);
          var json = await enviarJsonPOST("usuarios/cambiar_clave", fd);

          if (json.error == 0) {
            this.$router.push("/");
          }
        } else {
          json = {
            error: 1,
            message: "La clave y su confirmación no son iguales.",
          };
        }
        this.formChange.validating = false;
        await this.$emit("validarMensaje", json);
      }
    },

    cerrar: function () {
      sessionStorage.removeItem("emailValid");
      this.$router.push("/");
    },
    validarMensaje: function (msgAjax) {
      this.$emit("validarMensaje", msgAjax);
    },
    setUsuario: function (usuario) {
      this.$emit("setUsuario", usuario);
    },
    setFullLoading: function (visible, texto) {
      this.$emit("setFullLoading", visible, texto);
    },
    SalirSistema: function () {
      this.$emit("SalirSistema");
    },
  },
};
</script>

<style>
</style>