<template>
  <v-card
    class="rounded-xl align-center justify-center"
    :height="heightOptions"
    @click="registraCliente"
  >
    <v-card-text class="text-center text-h6">
      ¿Necesitas un crédito?
      <v-card class="rounded-xl mb-3"
          :style="'background-size: '+(isMobile?'110%':'150%')+';background-position: center;background-repeat: no-repeat;'
          +'height: 200px;background-image: url(\'/img/start/mas_info_creditos.jpeg\''">
      </v-card>
    </v-card-text>
  </v-card>

  <div class="text-md-center pa-2">
    <v-dialog
      v-model="dialog"
      transition="dialog-top-transition"
      fullscreen
      persistent
    >
      <v-card>
        <v-toolbar
          :style="
            'background-color:' +
            paletColor.fondoPincipal +
            ';color: ' +
            paletColor.letra2 +
            ';'
          "
        >
          <v-btn icon="mdi-close" @click="cerrar"></v-btn>

          <v-toolbar-title>
            <span class="text-h5 display-1 font-weight-bold">{{ title }}</span>
          </v-toolbar-title>

          <v-spacer></v-spacer>
        </v-toolbar>

        <v-container class="bg-blue-lighten-5 mt-2">
          <div class="text-h5">Registro de clientes</div>
          <div class="text-caption">
            <v-form
              ref="formNew"
              v-model="formNew.validForm"
              @submit.prevent="submitNew"
            >
              <h3 class="text-center">Solicitud de Crédito de consumo</h3>
              <div class="text-center text-caption">
                Registra que necesitas y los ejecutivos competirán por ofrecerte
                las mejores opciones.
                <br />Tú eliges las condiciones que más te convengan. ¡Tú
                decides!
              </div>
              <v-row align="center" justify="center">
                <v-col cols="12" class="mt-8">
                  <v-row>
                    <v-col cols="12">
                      <baseNuevoCreditoVue
                        @setValConsumo="setValConsumo"
                        @setValConsumo2="setValConsumo2"
                        @validarMensaje="validarMensaje"
                        :validating="formNew.validating"
                      ></baseNuevoCreditoVue>
                    </v-col>
                    <v-col cols="12" sm="8" class="border-col">
                      <formemail
                        labelSet="Email"
                        :requeridoSet="true"
                        :validatingSet="formNew.validating"
                        :valorSet="formNew.email"
                        ref="formEmail"
                      ></formemail>
                    </v-col>
                    <v-col cols="12" sm="4" class="border-col">
                      <formrut
                        labelSet="RUT"
                        :requeridoSet="true"
                        :validatingSet="formNew.validating"
                        :valorSet="formNew.rutUsuario"
                        ref="formRut"
                      ></formrut>
                    </v-col>
                    <v-col cols="12" sm="6" class="border-col">
                      <formtext
                        iconoSet="mdi-account-outline"
                        labelSet="Nombres"
                        :requeridoSet="true"
                        MensajeAyudaSet="Ingresu su nombre"
                        :validatingSet="formNew.validating"
                        :valorSet="formNew.nombres"
                        ref="formNombres"
                      ></formtext>
                    </v-col>

                    <v-col cols="12" sm="3" class="border-col">
                      <formtext
                        iconoSet="mdi-account-box-outline"
                        labelSet="Apellido paterno"
                        :requeridoSet="true"
                        MensajeAyudaSet="Ingresu su apellido paterno"
                        :validatingSet="formNew.validating"
                        :valorSet="formNew.app"
                        ref="formApp"
                      ></formtext>
                    </v-col>
                    <v-col cols="12" sm="3" class="border-col">
                      <formtext
                        iconoSet="mdi-account-box-outline"
                        labelSet="Apellido materno"
                        :requeridoSet="true"
                        MensajeAyudaSet="Ingresu su apellido materno"
                        :validatingSet="formNew.validating"
                        :valorSet="formNew.apm"
                        ref="formApm"
                      ></formtext>
                    </v-col>
                    <v-col cols="12" class="text-caption">
                      Al registrar una nueva solicitud de crédito,
                      inmediatamente estarás creando tu cuenta de usuario y esta
                      será validada con tu correo electrónico.
                      <br />Debes ingresar una cuenta de correo válida, la
                      validación de correo es necesaria para continuar con el
                      proceso.
                      <div class="border-thin pa-3 rounded-lg mt-4">
                        <span class="font-weight-medium"
                          >Nombre del crédito:</span
                        >
                        <span class="text-medium-emphasis"
                          >Corresponde a un nombre que a usted le haga
                          referencia de su necesidad de dinero.</span
                        >
                        <br /><span class="font-weight-medium"
                          >Destino del crédito:</span
                        >
                        <span class="text-medium-emphasis"
                          >Una breve descripción de la necesidad del dinero,
                          esto ayuda a los ejecutivos a darte mejores opciones o
                          consideraciones.</span
                        >
                        <br /><span class="font-weight-medium"
                          >Monto total cuotas mensuales:</span
                        >
                        <span class="text-medium-emphasis"
                          >Corresponde al monto total que paga a las distintas
                          entidades financieras de forma mensual.</span
                        >
                        <br /><span class="font-weight-medium"
                          >Deuda Total:</span
                        >
                        <span class="text-medium-emphasis"
                          >Corresponde al monto total o deuda capital al día de
                          hoy debes a las distintas entidades financieras.</span
                        >
                      </div>
                    </v-col>
                    <v-col cols="12" md="6">
                      <formbtnsend
                        labelSet="Registrar"
                        :validating="formNew.validating"
                      ></formbtnsend>
                    </v-col>
                    <v-col cols="12" md="6">
                      <buttonCancelformVue
                        labelSet="Calcelar"
                        :validating="formNew.validating"
                        @cerrar="cerrar"
                      ></buttonCancelformVue>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-form>
          </div>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>
  
  <script>
import { paletColor, enviarJsonPOST, sepa } from "@/helpers/funcionesEstandard";
import {
  rulesRut,
  rulesMail,
  rulesName,
  rulesApellido,
  rulesEmpresa,
  rulesClave,
  rulesNameCredito,
  rulesMonedaMayIgCero,
  rulesSueldo,
  rulesMontoCredConsumo,
  rulesCuotasConsumo,
} from "@/helpers/funcionesEstandard";

import formemail from "@/components/form/email.vue";
import formrut from "@/components/form/rut.vue";
import formtext from "@/components/form/textos.vue";
import formbtnsend from "@/components/form/buttonSubmitform.vue";
import buttonCancelformVue from "@/components/form/buttonCancelform.vue";
import baseNuevoCreditoVue from "@/components/Cliente/CreditoConsumo/baseNuevoCredito.vue";

export default {
  data: () => ({
    paletColor: paletColor,
    dialog: false,
    icon: "account-check",
    title: "Registro de clientes",
    heightOptions: 250,

    formNew: {
      validForm: null,
      validating: null,
      email: null,
      nombres: null,
      app: null,
      apm: null,
      nombreCredito: null,
      descripcionCredito: null,
      sueldoLiquido: null,
      deudaTotal: null,
      deudaMensual: null,
      rutUsuario: null,
      montoSolicitar: null,
      numeroCuotas: null,
      fechaPrimeraCuota: null,
      sd: null,
      ci: null,
      it: null,
      codigo_tipo_credito: null,
    },
    rulesMail: rulesMail,
    rulesRut: rulesRut,
    rulesName: rulesName,
    rulesApellido: rulesApellido,
    rulesEmpresa: rulesEmpresa,
    rulesClave: rulesClave,
    rulesNameCredito: rulesNameCredito,
    rulesMonedaMayIgCero: rulesMonedaMayIgCero,
    rulesSueldo: rulesSueldo,
    rulesMontoCredConsumo: rulesMontoCredConsumo,
    rulesCuotasConsumo: rulesCuotasConsumo,
    fechaMasSeisMeses: null,
  }),
  props: {
    user: Object,
    usuarioId: Number,
    ejecutivo: Object,
    ejecutivoId: Number,
    heightWindow: Number,
    widthWindow: Number,
    isMobile: Boolean,
  },
  components: {
    formemail,
    formrut,
    formtext,
    formbtnsend,
    buttonCancelformVue,
    baseNuevoCreditoVue,
  },
  methods: {
    setValConsumo2: function (clave, valor) {
      this.formNew[clave] = valor;
    },
    setValConsumo: async function (obj) {
      let claves = Object.keys(obj); // claves = ["nombre", "color", "macho", "edad"]
      for (let i = 0; i < claves.length; i++) {
        let clave = claves[i];
        this.formNew[clave] = obj[clave];
      }
    },
    cerrar: function () {
      this.dialog = false;
      this.$emit("cerrar");
    },
    submitNew: async function () {
      this.formNew.validating = true;
      const { valid } = await this.$refs.formNew.validate();
      if (valid) {
        let fd = new FormData();
        fd.append("personaId", this.formNew.personaId);
        fd.append("usuarioId", this.formNew.usuarioId);
        fd.append("email", this.$refs.formEmail.valor);
        fd.append("rutUsuario", this.$refs.formRut.valor);
        fd.append("nombres", this.$refs.formNombres.valor);
        fd.append("app", this.$refs.formApp.valor);
        fd.append("apm", this.$refs.formApm.valor);
        fd.append("montoSolicitar", this.formNew.montoSolicitar);
        fd.append("numeroCuotas", this.formNew.numeroCuotas);
        fd.append("fechaPrimeraCuota", this.formNew.fechaPrimeraCuota);
        fd.append("nombreCredito", this.formNew.nombreCredito);
        fd.append("descripcionCredito", this.formNew.descripcionCredito);

        fd.append("deudaTotal", this.formNew.deudaTotal);
        fd.append("deudaMensual", this.formNew.deudaMensual);
        fd.append("sueldoLiquido", this.formNew.sueldoLiquido);
        fd.append("sd", this.formNew.sd ? 1 : 0);
        fd.append("ci", this.formNew.ci ? 1 : 0);
        fd.append("it", this.formNew.it ? 1 : 0);
        fd.append("codigo_tipo_credito", this.formNew.codigo_tipo_credito);

        var json = await enviarJsonPOST("usuarios/crear_nueva_cuenta", fd);
        if (json.error == 0) {
          this.dialog = false;
          this.$emit("cerrar");
          sessionStorage.setItem("emailValid", this.$refs.formEmail.valor);
          await this.$router.push("/validMailCli/");
        } else {
          await this.$emit("validarMensaje", json);
        }
      }
      this.formNew.validating = false;
    },
    registraCliente: function () {
      this.dialog = true;
    },
    validarMensaje: function (msgAjax) {
      this.$emit("validarMensaje", msgAjax);
    },
    setEjecutivo: function (usuario) {
      this.$emit("setEjecutivo", usuario);
    },
    setUsuario: function (usuario) {
      this.$emit("setUsuario", usuario);
    },
    setFullLoading: function (visible, texto) {
      this.$emit("setFullLoading", visible, texto);
    },
    SalirSistema: function () {
      this.$emit("SalirSistema");
    },
  },
};
</script>
  
  <style>
</style>