<template>
  <v-text-field
    :label="label"
    variant="outlined"
    type="password"
    :autofocus="autofocus"
    ref="password"
    v-model="valor"
    density="compact"
    autocomplete="false"
    prepend-inner-icon="mdi-lock"
    placeholder="AaAaAa123456"
    :hint="MensajeAyuda"
    :readonly="readonly"
    :loading="validating"
    :disabled="validating"
    :maxLength= "maxLength"
    :rules="[
      this.requerido && rules.requerido ? rules.requerido : null,
      (this.valor != null && this.minLength > this.valor.length) 
        ? 'Mínimo '+ this.separadorMiles(this.minLength)+' digitos.'
        : null,
      (this.valor != null && this.maxLength < this.valor.length) 
        ? 'Máximo '+ this.separadorMiles(this.maxLength)+' digitos.'
        : null,
      rules.formato,
    ]"
    validate-on-blur
  ></v-text-field>
</template>
  
  <script>
export default {
  data: () => ({
    valor: null,
    validating: false,
    label: "Clave",
    autofocus: false,
    requerido: false,
    readonly: false,
    MensajeAyuda: 'Ingrese su clave secreta',
    minLength: 8,
    maxLength: 50,
    rules: {
      requerido(v) {
        if (!v) {
          return "Es requerido.";
        }
      },
      formato(v) {
        // Expresión regular para validar el correo electrónico
        /*(?=.*[A-Z]): Al menos una letra mayúscula.
        (?=.*[a-z]): Al menos una letra minúscula.
        (?=.*\d): Al menos un número.
        (?=.*[!@#$%^&*()_+]): Al menos un símbolo de los especificados.
        [A-Za-z\d!@#$%^&*()_+]{10,}: Debe contener al menos 10 caracteres de los especificados.
        var regexCorreo = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d!@#$%^&*()_+]{8,}$/;
        var regexCorreo = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)[A-Za-z\d!@#$%^&*()_+]{8,}$/;*/

        // Al menos una mayúscula, una minúscula y un número
        var tieneMayuscula = /[A-Z]/.test(v);
        var tieneMinuscula = /[a-z]/.test(v);
        var tieneNumero = true;///\d/.test(v);

        if (!tieneMayuscula || !tieneMinuscula || !tieneNumero) {
          return 'Clave inválida.';
        }

        /*// Opcionalmente, un símbolo
        var tieneSimbolo = /[-!@#$%^&*()_+|~=`{}\[\]:";'<>?,.\/]/.test(clave);
        if (!tieneSimbolo) {
            console.log("no tiene simbolo")
        }*/

        // Si cumple con todos los criterios
      },
    },
  }),
  props: {
    valorSet: String,
    validatingSet: Boolean,
    labelSet: String,
    autofocusSet: Boolean,
    requeridoSet: Boolean,
    readonlySet: Boolean,
    minLengthSet: Number,
    maxLengthSet: Number,
    MensajeAyudaSet: String
  },
  watch: {
    valorSet: function (val) {
      this.valor = val;
    },
    validatingSet: function (val) {
      this.validating = val;
    },
    labelSet: function (val) {
      this.label = val;
    },
    autofocusSet: function (val) {
      this.autofocus = val;
    },
    requeridoSet: function (val) {
      this.requerido = val;
    },
    readonlySet: function (val) {
      this.readonly = val;
    },
    minLengthSet: function (val) {
      this.minLengthSet = val;
    },
    maxLengthSet: function (val) {
      this.maxLength = val;
    },
    MensajeAyudaSet: function (val) {
      this.MensajeAyuda = val;
    },
  },
  mounted: function () {
    if (this.valorSet) {
      this.valor = this.valorSet;
    }
    if (this.validatingSet) {
      this.validating = this.validatingSet;
    }
    if (this.labelSet) {
      this.label = this.labelSet;
    }
    if (this.autofocusSet) {
      this.autofocus = this.autofocusSet;
    }
    if (this.requeridoSet) {
      this.requerido = this.requeridoSet;
    }
    if (this.readonlySet) {
      this.readonly = this.readonlySet;
    }
    if (this.minLengthSet) {
      this.minLength = this.minLengthSet;
    }
    if (this.maxLengthSet) {
      this.maxLength = this.maxLengthSet;
    }
    if (this.MensajeAyudaSet) {
      this.MensajeAyuda = this.MensajeAyudaSet;
    }
  },
  methods: {
    separadorMiles: function (numero) {
      // Validar que el número sea un valor numérico
      if (isNaN(numero)) {
        return "0";
      }
      numero = parseFloat(numero);
      // Usar toLocaleString para formatear el número con separador de miles
      return numero.toLocaleString("es-CL");
    },
  }
};
</script>
  
  <style>
</style>