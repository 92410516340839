<template flat>
    <v-btn
      :style="'background-color: '+paletColor.botonSubmit1+';color: '+paletColor.letra2+';'"
      dark
      block
      title
      type="submit"
      :loading="validating"
      >{{ label }}</v-btn
    >
</template>

<script>
import {paletColor} from "@/helpers/funcionesEstandard";
export default {
  data: () => ({
    label: 'Enviar',
    paletColor: {
      fondoPincipal: paletColor.fondoPincipal,
      fondoGeneral: paletColor.fondoGeneral,
      fondoPagina: paletColor.fondoPagina,
      letra1: paletColor.letra1,
      letra2: paletColor.letra2,
      botonSubmit1: paletColor.botonSubmit1,
      botonSubmit2: paletColor.botonSubmit2,
      botonCancel1: paletColor.botonCancel1,
      botonCancel2: paletColor.botonCancel2,
    },
  }),
  props: {
    validating: Boolean,
    labelSet: String,
  },
  watch: {
    labelSet: function(v){
      this.label = v;
    }
  },
  mounted: function(){
    if(this.labelSet){
      this.label = this.labelSet;
    }
  }
};
</script>

<style>
</style>