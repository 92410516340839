const URLBase = process.env.VUE_APP_RUTA_API;
const VERSION = process.env.VUE_APP_VERSION;
const NODE_ENV = process.env.NODE_ENV;

module.exports.paletColor = {
    fondoPincipal: '#2B192E',
    fondoGeneral: '#EBEFF4',
    fondoPagina: '#EBEFF4',
    fondoPagina2: '#F5E8DA',
    letra1: '#000000',
    letra2: '#FFFFFF',
    botonSubmit1: '#702995',
    botonSubmit2: '#F0CDFF',
    botonCancel1: '#F0CDFF',
    botonCancel2: '#D8BEDC',
  };

module.exports.rulesCuotasConsumo = {
    requerido: value => !!value || 'Este valor es requerido.',
    minChar: value => (value && value.length >= 1) || 'Debe tener al menos 1 dígito.',
    maxChar: value => (value && value.length < 3) || 'Debe tener menos de 3 digitos.',
    minVal: value => (value >= 6) || 'EL monto debe ser mayor o igual que 6.',
    maxVal: value => (value <= 72) || 'EL monto debe ser inferior o igual a 72',
};

module.exports.rulesMontoCredConsumo = {
    requerido: value => !!value || 'Este monto es requerido.',
    minChar: value => (value && value.length >= 1) || 'Debe tener al menos 1 dígito.',
    maxChar: value => (value && value.length < 9) || 'Debe tener menos de 9 digitos.',
    minVal: value => (value >= 1000000) || 'EL monto debe ser mayor que $1.000.000.',
    maxVal: value => (value < 10000000000) || 'EL monto debe ser inferior a $9.000.000.000.',
};

module.exports.rulesSueldo = {
    requerido: value => !!value || 'Este monto es requerido.',
    minChar: value => (value && value.length >= 1) || 'Debe tener al menos 1 dígito.',
    maxChar: value => (value && value.length < 9) || 'Debe tener menos de 9 digitos.',
    minVal: value => (value >= 200000) || 'EL monto debe ser mayor que $200.000.',
    maxVal: value => (value < 10000000000) || 'EL monto debe ser inferior a $9.000.000.000.',
};

module.exports.rulesMonedaMayIgCero = {
    requerido: value => !!value || 'Este monto es requerido.',
    minChar: value => (value && value.length >= 1) || 'Debe tener al menos 1 dígito.',
    maxChar: value => (value && value.length < 9) || 'Debe tener menos de 9 digitos.',
    minVal: value => (value >= 0) || 'EL monto debe ser mayor o igual a cero.',
    maxVal: value => (value < 10000000000) || 'EL monto debe ser inferior a $9.000.000.000.',
};

module.exports.rulesNameCredito = {
    requerido: value => !!value || 'El nombre del crédito es requerido.',
    minChar: value => (value && value.length >= 5) || 'Debe tener al menos 5 dígitos.',
    maxChar: value => (value && value.length < 50) || 'No debe tener mas de 50 digitos.',
};

module.exports.rulesClave = {
    valida: value => validarClave(value) || 'La clave no es vádila.',
    requerido: value => !!value || 'La clave es requerida.',
    minChar: value => (value && value.length >= 8) || 'Debe tener al menos 10 caracteres.',
    maxChar: value => (value && value.length < 50) || 'No debe tener mas de 50 caracteres.',
};

module.exports.rulesRut = {
    valida: value => validarRut(value) || 'El rut ingresado no es válido.',
    requerido: value => !!value || 'El rut es requerido',
    minChar: value => (value && value.length >= 7) || 'Debe tener al menos 8 dígitos (000000-K).',
    maxChar: value => (value && value.length < 50) || 'No debe tener mas de 50 caracteres.',
};

module.exports.rulesMail = {
    requerido: value => !!value || 'El Email es requerido.',
    valida: value => validarCorreo(value) || 'El correo ingresado no es válido.',
    minChar: value => (value && value.length >= 10) || 'Debe tener al menos 10 dígitos (aaa@aaa.cl).',
    maxChar: value => (value && value.length < 50) || 'No debe tener mas de 50 caracteres.',
};

module.exports.rulesName = {
    requerido: value => !!value || 'El nombre es requerido.',
    minChar: value => (value && value.length >= 3) || 'Debe tener al menos 2 dígitos.',
    maxChar: value => (value && value.length < 50) || 'No debe tener mas de 50 caracteres.',
};

module.exports.rulesApellido = {
    requerido: value => !!value || 'El apellido es requerido.',
    minChar: value => (value && value.length >= 3) || 'Debe tener al menos 2 dígitos.',
    maxChar: value => (value && value.length < 30) || 'No debe tener mas de 30 caracteres.',
};

module.exports.rulesEmpresa = {
    requerido: value => !!value || 'El nombre de empresa es requerido.',
    minChar: value => (value && value.length >= 5) || 'Debe tener al menos 5 dígitos.',
    maxChar: value => (value && value.length < 200) || 'No debe tener mas de 200 caracteres.',
};


module.exports.formatoFechaVue = function (date) {

    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();
    if (month > 12) {
        month = 1;
        year++;
    }

    day = "" + (day < 10 ? "0" : "") + day;
    month = "" + (month < 10 ? "0" : "") + month;
    year = "" + (year < 999 ? "0" : "") + year;

    let fechaText = `${year}-${month}-${day}`;

    return fechaText;
}

module.exports.formatoFechaTXT = function (val) {

    let f = new Date(val);
    fTXT =
        ((f.getDate() + 1)<9 ?'0':'')+
        (f.getDate() + 1) +
        '-' +
        (f.getMonth() < 9 ? '0' : '') +
        (f.getMonth() + 1) +
        '-' +
        f.getFullYear();
    return fTXT;
}


module.exports.agregarMeses = async function (date, addMonth) {
    var fechanueva = new Date(date.setMonth(date.getMonth() + addMonth));
    return fechanueva;
}

/**
 * Permite hacer un llamado ajax con metodo GET
 * @param {*} urlSend Corresponde a la URL relativa del servidor donde hacer el llamado
 * @param {*} SendData datos que se desean enviar (debe ser en modo objeto javascript)
 * @returns 
 */
module.exports.obtenerJsonGET = async function (urlSend, SendData) {
    URLSend = URLBase + urlSend;

    var returnData = {
        error: 0,
        message: '',
        data: ''
    }
    try {

        var user = null;

        if (sessionStorage.getItem("user") || sessionStorage.getItem("ejecutivo")) {

            user = JSON.parse(sessionStorage.getItem("user"));
            ejecutivo = JSON.parse(sessionStorage.getItem("ejecutivo"));
            if(ejecutivo.token){
                user = ejecutivo;
            }
        }
         else {
            user = {
                token: null
            };
        }

        if (!user) {
            user = {
                token: null
            };
        }






        var dataSend = {
            token: user.token,
            mode: 'cors',
            modo: NODE_ENV,
        };
        for (const property in SendData) {
            dataSend[property] = SendData[property];
        }
        const url = new URL(URLSend);
        url.search = new URLSearchParams(dataSend);

        const data = await fetch(url);
        const json = await data.json();
        returnData = json;
    } catch (err) {
        returnData.error = -1;
        returnData.message = "Esto es un error al intentar solciitar información GET: " + err;
    }
    return returnData;
}

/**
 * Permite hacer un llamado ajax con metodo POST
 * @param {*} urlSend Corresponde a la URL relativa del servidor donde hacer el llamado
 * @param {*} data2 datos que se desean enviar (debe ser en modo objeto FormData())
 * @returns 
 */
module.exports.enviarJsonPOST = async function (urlSend, data2) {
    URLSend = URLBase + urlSend;
    var returnData = {
        error: 0,
        message: '',
        data: ''
    }

    try {
        var user = null;

        if (sessionStorage.getItem("user") || sessionStorage.getItem("ejecutivo")) {

            user = JSON.parse(sessionStorage.getItem("user"));
            ejecutivo = JSON.parse(sessionStorage.getItem("ejecutivo"));
            if(ejecutivo.token){
                user = ejecutivo;
            }
        }
         else {
            user = {
                token: null
            };
        }

        if (!user) {
            user = {
                token: null
            };
        }
        if (user) {
            if (user.token) {
                data2.append("token", user.token);
            } else {
                data2.append("token", null);
            }
        } else {
            data2.append("token", null);
        }

































        const setting = {
            method: "POST",
            modo: NODE_ENV,
            body: data2,
        };
        const resp = await fetch(URLSend, setting);
        if (!resp.ok || resp.headers.get('Content-Type') != 'application/json') {
            throw new Error('Algo Salio mal');
        }
        const json = await resp.json();
        return json;
    } catch (err) {
        returnData.error = -1;
        returnData.message = "Esto es un error al intentar solciitar información POST: " + err;
    }
    return returnData;
}

module.exports.formatoMonedaChile = function (numero, decimales) {
    if (isNaN(numero)) {
        return null;
    }

    // Formatear como moneda chilena
    return new Intl.NumberFormat('es-CL', {
        style: 'currency',
        currency: 'CLP',
        minimumFractionDigits: decimales,
    }).format(numero);
}


module.exports.formatearNumeroComoPorcentaje = function(numero) {
    if (isNaN(numero)) {
        return null;
    }
    let a = parseFloat(numero);
    // Convertir el número a una cadena con dos decimales
    let numeroFormateado = a.toFixed(2);
    // Añadir el signo de porcentaje al final
    return `${numeroFormateado}%`;
}

module.exports.formatoIDCreditoConsumo = function(value) {
    
    let strValue = '_S/N';
    if(value){
    // Convertir el valor a cadena
        strValue = String(value);
    }

    // Completar con ceros a la izquierda hasta tener 9 dígitos
    return "CC"+strValue.padStart(9, '0');
}

validarClave = function (clave) {
    // Longitud mínima de 10 caracteres
    if (clave.length < 8) {
        return false;
    }


    // Expresión regular para validar el correo electrónico
    /*(?=.*[A-Z]): Al menos una letra mayúscula.
    (?=.*[a-z]): Al menos una letra minúscula.
    (?=.*\d): Al menos un número.
    (?=.*[!@#$%^&*()_+]): Al menos un símbolo de los especificados.
    [A-Za-z\d!@#$%^&*()_+]{10,}: Debe contener al menos 10 caracteres de los especificados.
    var regexCorreo = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d!@#$%^&*()_+]{8,}$/;
    var regexCorreo = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)[A-Za-z\d!@#$%^&*()_+]{8,}$/;*/


    // Al menos una mayúscula, una minúscula y un número
    var tieneMayuscula = /[A-Z]/.test(clave);
    var tieneMinuscula = /[a-z]/.test(clave);
    var tieneNumero = /\d/.test(clave);

    if (!tieneMayuscula || !tieneMinuscula || !tieneNumero) {
        return false;
    }

    /*// Opcionalmente, un símbolo
    var tieneSimbolo = /[-!@#$%^&*()_+|~=`{}\[\]:";'<>?,.\/]/.test(clave);
    if (!tieneSimbolo) {
        console.log("no tiene simbolo")
    }*/

    // Si cumple con todos los criterios
    return true;
}

/**
 * Permite validar si un rut es válido o no
 * @param {*} rut Corresponde al rut que se desea revisar
 * @returns 
 */
validarRut = function (rut) {
    // Eliminar puntos y guión
    if (rut) {
        rut = rut.replace(/\./g, "").replace(/\-/g, "");
        rut = rut.replace(/\-/g, "");

        // Separar número y dígito verificador
        var rutNumero = rut.slice(0, -1);
        var rutDV = rut.slice(-1).toUpperCase();

        // Validar que el número tenga al menos 7 dígitos
        if (rutNumero.length < 7) {
            return false;
        }

        // Calcular el dígito verificador esperado
        var suma = 0;
        var multiplo = 2;
        for (var i = rutNumero.length - 1; i >= 0; i--) {
            suma += parseInt(rutNumero.charAt(i)) * multiplo;
            multiplo = multiplo < 7 ? multiplo + 1 : 2;
        }
        var dvEsperado = 11 - (suma % 11);
        dvEsperado = (dvEsperado === 11) ? "0" : (dvEsperado === 10) ? "K" : dvEsperado.toString();

        // Comparar el dígito verificador calculado con el ingresado
        return rutDV === dvEsperado;
    } else {
        return true;
    }
}

validarCorreo = function (correo) {

    if (correo) {
        // Expresión regular para validar el correo electrónico
        var regexCorreo = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

        // Validar el correo electrónico con la expresión regular
        return regexCorreo.test(correo);
    } else {
        return true;
    }
}


