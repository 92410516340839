<template>
  <v-dialog
    v-model="dialogOn"
    transition="dialog-top-transition"
    max-width="600"
    min-width="400"
    width="80%"
    persistent
  >
    <v-card>
      <v-toolbar
        :style="
          'background-color:' +
          paletColor.fondoPincipal +
          ';color: ' +
          paletColor.letra2 +
          ';'
        "
      >
        <v-btn icon="mdi-close" @click="cerrar"></v-btn>

        <v-toolbar-title>
          <span class="text-h5 display-1 font-weight-bold">{{ title }}</span>
        </v-toolbar-title>

        <v-spacer></v-spacer>
      </v-toolbar>

      <v-container
        class="text-center"
        :style="'background-color:' + paletColor.fondoGeneral + ';'"
      >
        <div class="text-h5">¡Registrate con nosotos!</div>
        <div class="text-caption">Un texto llamativo</div>
        <div>
          <v-row>
            <v-col cols="12" md="6">
              <registerClientVue
                :user="user"
                :usuarioId="usuarioId"
                :ejecutivo="ejecutivo"
                :ejecutivoId="ejecutivoId"
                :heightWindow="widthWindow"
                :widthWindow="widthWindow"
                :isMobile="isMobile"
                @validarMensaje="validarMensaje"
                @setEjecutivo="setEjecutivo"
                @setUsuario="setUsuario"
                @setFullLoading="setFullLoading"
                @SalirSistema="SalirSistema"
                @cerrar="cerrar"
              ></registerClientVue>
            </v-col>
            <v-col cols="12" md="6">
              <registerExecutiveVue
                :user="user"
                :usuarioId="usuarioId"
                :ejecutivo="ejecutivo"
                :ejecutivoId="ejecutivoId"
                :heightWindow="widthWindow"
                :widthWindow="widthWindow"
                :isMobile="isMobile"
                @validarMensaje="validarMensaje"
                @setEjecutivo="setEjecutivo"
                @setUsuario="setUsuario"
                @setFullLoading="setFullLoading"
                @SalirSistema="SalirSistema"
                @cerrar="cerrar"
              >
              </registerExecutiveVue>
            </v-col>
          </v-row>
        </div>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import { paletColor, enviarJsonPOST } from "@/helpers/funcionesEstandard";
import registerExecutiveVue from "@/components/Banca/Seguridad/registerExecutive.vue";
import registerClientVue from "@/components/Cliente/Seguridad/registerClient.vue";

export default {
  data: () => ({
    dialogOn: false,
    icon: "account-check",
    title: "Regístrate",
    heightOptions: 300,
    paletColor: paletColor,
  }),
  props: {
    user: Object,
    usuarioId: Number,
    ejecutivo: Object,
    ejecutivoId: Number,
    dialog: Object,
    heightWindow: Number,
    widthWindow: Number,
    isMobile: Boolean,
  },
  watch: {
    dialog: function (val) {
      this.dialogOn = val;
    },
  },
  mounted: function () {
    this.dialogOn = this.dialog;
  },
  components: {
    registerClientVue,
    registerExecutiveVue,
  },
  methods: {
    cerrar: function () {
      this.$emit("cerrarAccion", "registrar");
    },
    closeRegister: function () {
      this.dialogOn = false;
    },
    registraEjecutivo: function () {
      alert("registra Ejecutivo");
    },
    validarMensaje: function (msgAjax) {
      this.$emit("validarMensaje", msgAjax);
    },
    setEjecutivo: function (usuario) {
      this.$emit("setEjecutivo", usuario);
    },
    setUsuario: function (usuario) {
      this.$emit("setUsuario", usuario);
    },
    setFullLoading: function (visible, texto) {
      this.$emit("setFullLoading", visible, texto);
    },
    SalirSistema: function () {
      this.$emit("SalirSistema");
    },
  },
};
</script>

<style>
</style>