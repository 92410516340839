<template>
  <v-toolbar
    flat
    app
    :style="
      'background-color:' +
      paletColor.fondoPincipal +
      ';color: ' +
      paletColor.letra2 +
      ';'
    "
  >
    <v-toolbar-title>
      <v-img src="/img/start/cliente.jpg" width="30"></v-img>
      Cliente
    </v-toolbar-title>
    <v-spacer></v-spacer>
    <v-btn
      @click="SalirSistema"
      :style="
        'background-color:' +
        paletColor.botonSubmit2 +
        ';color: ' +
        paletColor.letra1 +
        ';'
      "
      >Salir</v-btn
    >
  </v-toolbar>
  <v-row align="center" justify="center">
    <v-col cols="12" md="11">
      <v-row class="mt-10 ml-5 mr-5 mb-5" align="center" justify="center">
        <v-col cols="12">
          Bienvenido
          <span class="text-blue-darken-4 font-weight-black">{{
            user.name
          }}</span
          >, estos son las solicitudes vigentes que tienes:
        </v-col>
        <v-col cols="12" md="11" v-for="item in creditos" :key="item">
          <solicitudCreditoConsumoVue
            :nombre="item.nombre"
            :descripcion="item.descripcion"
            :monto_solicitado="item.monto_solicitado"
            :numero_cuotas="item.numero_cuotas"
            :fecha_primera_cuota="item.fecha_primera_cuota"
            :seguro_desgravament="
              item.seguro_desgravament == '1' ? true : false
            "
            :seguro_cesantia="item.seguro_cesantia == '1' ? true : false"
            :seguro_incapacidad="item.seguro_incapacidad == '1' ? true : false"
            :fecha_creacion="item.fechacrea_at"
            :id="item.id"
            :ofertas="item.cantidadOfertas"
            paginaRemote="0"
            :sueldo="item.sueldo_liquido"
            :deuda_mensual="item.deuda_mensual"
            :deuda_total="item.deuda_total"
            :TipoCredito="item.TipoCredito"
            :ofertasAceptadas="item.aceptadas"
            :ofertasPendientes="item.pendiente"
            @loadItems="obtenerSolicitudes"
            :user="user"
            :usuarioId="usuarioId"
            :paletColor="paletColor"
            :heightWindow="widthWindow"
            :widthWindow="widthWindow"
            :isMobile="isMobile"
            @validarMensaje="validarMensaje"
            @setUsuario="setUsuario"
            @setEjecutivo="setEjecutivo"
            @setFullLoading="setFullLoading"
            @SalirSistema="SalirSistema"
            @cargarSolicitudes="obtenerSolicitudes"
          />
        </v-col>
      </v-row>
    </v-col>
  </v-row>
  <nuevoCredito
    :dialog="dialogNuevoConsumo"
    :user="user"
    :usuarioId="usuarioId"
    :ejecutivo="ejecutivo"
    :ejecutivoId="ejecutivoId"
    :heightWindow="widthWindow"
    :widthWindow="widthWindow"
    :isMobile="isMobile"
    @validarMensaje="validarMensaje"
    @setEjecutivo="setEjecutivo"
    @setUsuario="setUsuario"
    @setFullLoading="setFullLoading"
    @SalirSistema="SalirSistema"
    @cerrarAccion="cerrarAccion"
    @obtenerSolicitudes="obtenerSolicitudes"
  />
</template>

<script>
import { paletColor, obtenerJsonGET } from "@/helpers/funcionesEstandard";
import botonRapido from "@/components/Usuario/principal/botonRapido.vue";
import solicitudCredito from "@/components/Usuario/principal/creditoSolicitado.vue";
import opcionesMenuRapido from "@/components/Usuario/principal/opcionesMenuRapido.vue";
import solicitudCreditoConsumoVue from "@/components/Cliente/CreditoConsumo/solicitudCreditoConsumo.vue";
import nuevoCredito from "@/components/Cliente/CreditoConsumo/nuevoCredito.vue";

export default {
  data: () => ({
    creditos: null,
    paletColor: paletColor,
  }),
  components: {
    botonRapido,
    solicitudCredito,
    opcionesMenuRapido,
    solicitudCreditoConsumoVue,
    nuevoCredito,
  },
  props: {
    user: Object,
    usuarioId: Number,
    heightWindow: Number,
    widthWindow: Number,
    isMobile: Boolean,
    dialogNuevoConsumo: Boolean,
  },
  mounted: async function () {
    if (!this.user) {
      this.$router.push("/");
    } else {
      this.obtenerSolicitudes();
    }
  },
  methods: {
    cerrarAccion: function(val){
      this.$emit("cerrarAccion",val);
    },
    obtenerSolicitudes: async function () {
      this.setFullLoading(true, "Obteniendo listado de solicitudes.");
      if (!this.user.token) {
        this.$router.push("/");
      } else {
        var SendData = {
          usuarioId: this.user.usuarioId,
        };
        var resp = await obtenerJsonGET(
          "principal/obtener_solicitudes",
          SendData
        );
        if (resp.error == 0) {
          this.creditos = resp.data;
        } else {
          this.validarMensaje(resp);
        }
      }
      this.setFullLoading(false, null);
    },
    validarMensaje: function (obj) {
      this.$emit("validarMensaje", obj);
    },
    opcionRapida: async function (paso) {
      alert("crea uno nuevo");
    },
    setUsuario: function (usuario) {
      this.$emit("setUsuario", usuario);
    },
    setFullLoading: function (visible, texto) {
      this.$emit("setFullLoading", visible, texto);
    },
    SalirSistema: function () {
      this.$emit("SalirSistema");
    },
  },
};
</script>
