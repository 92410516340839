<template>
  <v-card
    class="rounded-xl align-center justify-center"
    :height="heightOptions"
    @click="registraCliente"
  >
    <v-card-text class="text-center text-h6">
      Soy Cliente
      <v-card class="rounded-xl mb-3"
          :style="'background-size: '+(isMobile?'110%':'150%')+';background-position: center;background-repeat: no-repeat;'
          +'height: 200px;background-image: url(\'/img/start/mas_info_creditos.jpeg\''">
      </v-card>
    </v-card-text>
  </v-card>
  <div class="text-md-center pa-2">
    <v-dialog
      v-model="dialog"
      transition="dialog-top-transition"
      max-width="600"
      min-width="400"
      width="80%"
      persistent
    >
      <v-card>
        <v-toolbar :style="'background-color:'+paletColor.fondoPincipal+';color: '+paletColor.letra2+';'">
          <v-btn icon="mdi-close" @click="dialog = false"></v-btn>

          <v-toolbar-title>
            <span class="text-h5 display-1 font-weight-bold">{{ title }}</span>
          </v-toolbar-title>

          <v-spacer></v-spacer>
        </v-toolbar>

        <v-container class="bg-blue-lighten-5 mt-2">
          <div class="text-h5">{{ title }}</div>
          <div class="text-caption">
            <v-form
              ref="loginForm"
              v-model="loginForm.validForm"
              @submit.prevent="submitNew"
            >
              <v-row align="center" justify="center">
                <v-col cols="12" md="5">
                  <v-img src="https://blog.cobistopaz.com/hubfs/01-6.png" />
                </v-col>
                <v-col cols="12" md="7">
                  <v-row align="center" justify="center">
                    <v-col cols="12" sm="8" class="mt-16 border-col">
                      <emailVue
                        labelSet="Email"
                        :requeridoSet="true"
                        :validatingSet="loginForm.validating"
                        :focus="true"
                        ref="formEmail"
                      ></emailVue>
                    </v-col>
                    <v-col cols="12" sm="8" class="mt-1 border-col">
                      <passwordVue
                        labelSet="Clave"
                        :requeridoSet="true"
                        :validatingSet="loginForm.validating"
                        ref="formPassword"
                      ></passwordVue>
                    </v-col>
                    <v-col cols="12" sm="8" class="mt-1 border-col">
                      <v-row>
                        <v-col cols="12">
                          <span
                            class="text-caption text-blue cursor-pointer"
                            @click="recuperarClave"
                            >Recuperar Clave</span
                          >
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" md="6">
                  <buttonSubmitformVue
                    labelSet="Ingresar"
                    :validating="loginForm.validating"
                  ></buttonSubmitformVue>
                </v-col>
                <v-col cols="12" md="6">
                  <buttonCancelformVue
                    @cerrar="cerrar"
                    labelSet="Calcelar"
                    loginForm.validating
                  ></buttonCancelformVue>
                </v-col>
              </v-row>
            </v-form>
          </div>
        </v-container>
      </v-card>
    </v-dialog>
  </div>

  <div class="text-md-center pa-2">
    <v-dialog
      v-model="dialog2"
      transition="dialog-top-transition"
      max-width="600"
      min-width="400"
      width="80%"
      persistent
    >
      <v-card>
        <v-toolbar :style="'background-color:'+paletColor.fondoPincipal+';color: '+paletColor.letra2+';'">
          <v-btn icon="mdi-close" @click="dialog2 = false"></v-btn>

          <v-toolbar-title>
            <span class="text-h5"
              >Recuperación de clave</span
            >
          </v-toolbar-title>

          <v-spacer></v-spacer>
        </v-toolbar>

        <v-container class="bg-blue-lighten-5 mt-2">
          <div class="text-h5">Recuperación de clave</div>
          <div class="text-caption">
            <v-form
              ref="loginRecuperar"
              v-model="recuperarForm.validForm"
              @submit.prevent="recuperar"
            >
              <v-row align="center" justify="center">
                <v-col cols="12" sm="8" class="mt-16 border-col">
                  <emailVue
                    labelSet="Email"
                    :requeridoSet="true"
                    :validatingSet="recuperarForm.validating"
                    :focus="true"
                    ref="formEmail"
                  ></emailVue>
                </v-col>
                <v-col cols="12" md="6">
                  <buttonSubmitformVue
                    labelSet="Solicitar cambio"
                    :validating="recuperarForm.validating"
                  ></buttonSubmitformVue>
                </v-col>
                <v-col cols="12" md="6">
                  <buttonCancelformVue
                    @cerrar="cerrar"
                    labelSet="Calcelar"
                    :validating="recuperarForm.validating"
                  ></buttonCancelformVue>
                </v-col>
              </v-row>
            </v-form>
          </div>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { paletColor,enviarJsonPOST } from "@/helpers/funcionesEstandard";
import emailVue from "@/components/form/email.vue";
import passwordVue from "@/components/form/password.vue";
import buttonSubmitformVue from "@/components/form/buttonSubmitform.vue";
import buttonCancelformVue from "@/components/form/buttonCancelform.vue";

export default {
  data: () => ({
    dialog: false,
    dialog2: false,
    icon: "account-check",
    title: "Ingreso de clientes",
    heightOptions: 250,
    loginForm: {
      validForm: null,
      validating: false,
      mail: null,
      password: null,
    },
    recuperarForm: {
      validForm: null,
      validating: false,
      mail: null,
    },
    paletColor: paletColor,
  }),
  components: {
    emailVue,
    passwordVue,
    buttonSubmitformVue,
    buttonCancelformVue,
  },
  props:{
  	user: Object,
  	usuarioId: Number,
  	ejecutivo: Object,
  	ejecutivoId: Number,
    heightWindow: Number,
    widthWindow: Number,
    isMobile: Boolean,
  },
  methods: {
    recuperar: async function () {
      this.recuperarForm.validating = true;
      const { valid } = await this.$refs.loginRecuperar.validate();
      if (valid) {
        let fd = new FormData();
        this.recuperarForm.mail = this.$refs.formEmail.valor;
        fd.append("email", this.recuperarForm.mail);
        var json = await enviarJsonPOST("usuarios/recuperar_clave", fd);

        if (json.error != 0) {
          this.$emit("validarMensaje", json);
        } else {
          this.$emit("validarMensaje", json);
          sessionStorage.setItem("emailValid", this.recuperarForm.mail);
          this.cerrar();
          this.$router.push("/changePasswordCli/");
        }
      }
      this.recuperarForm.validating = false;
    },
    recuperarClave: function () {
      this.dialog = false;
      this.dialog2 = true;
    },
    registraCliente: function () {
      this.dialog = true;
    },
    cerrar: function () {
      this.dialog = false;
      this.$emit("cerrar");
    },
    submitNew: async function () {
      this.loginForm.validating = true;
      const { valid } = await this.$refs.loginForm.validate();
      if (valid) {
        let fd = new FormData();
        this.loginForm.mail = this.$refs.formEmail.valor;
        this.loginForm.password = this.$refs.formPassword.valor;
        fd.append("email", this.loginForm.mail);
        fd.append("clave", this.loginForm.password);
        var json = await enviarJsonPOST("usuarios/validar_ingreso_usuario", fd);

        if (json.error != 0) {
          await this.$emit("validarMensaje", json);
        } else {
          if (
            json.data.estado_usuario == 200 ||
            json.data.estado_usuario == 300
          ) {
            sessionStorage.setItem("emailValid", this.loginForm.mail);
            this.cerrar();
            this.$router.push("/changePasswordCli/");
          } else {
            this.$emit("setUsuario", json.data);
            this.cerrar();
            this.$router.push("/cliente/");
          }
        }
      }
      this.loginForm.validating = false;
    },
    validarMensaje: function (msgAjax) {
      this.$emit("validarMensaje", msgAjax);
    },
    setEjecutivo: function (usuario) {
      this.$emit("setEjecutivo", usuario);
    },
    setUsuario: function (usuario) {
      this.$emit("setUsuario", usuario);
    },
    setFullLoading: function (visible, texto) {
      this.$emit("setFullLoading", visible, texto);
    },
    SalirSistema: function () {
      this.$emit("SalirSistema");
    },
  },
};
</script>

<style>
</style>