<template>
  <v-card
    border="opacity-50 sm"
    :class="thisclass"
    rounded="xl"
    variant="text"
    @click="aceptarOferta(oferta)"
  >
    <v-card-title
      :style="
        'background-color:' +
        paletColor.fondoPincipal +
        ';color: ' +
        paletColor.letra2 +
        ';'
      "
    >
      <div>
        {{ oferta.estado_oferta }}
      </div>
    </v-card-title>
    <v-card-text>
      <v-row class="ma-1">
        <v-col cols="12" class="border-col">
          <div class="text-h6 text-sm-h5 font-weight-bold border-col">
            Monto: {{ formatoMonedaChile(oferta.monto_ofertado) }}
            <v-tooltip activator="parent" location="bottom">
              Corresponde a monto que le otorgarán por el crédito
            </v-tooltip>
          </div>
          <div
            class="text-caption text-sm-h6 text-blue-accent-1 font-weight-bold border-col"
          >
            {{ oferta.numero_cuotas }} Cuotas de
            <v-tooltip activator="parent" location="bottom">
              Corresponde a la cantidad de cuotas que deberá pagar
            </v-tooltip>
          </div>
        </v-col>
        <v-col cols="12" class="border-col">
          <div
            class="text-h6 text-sm-h4 text-blue-darken-4 font-weight-black border-col"
          >
            {{ formatoMonedaChile(oferta.valor_cuota) }}
            <v-tooltip activator="parent" location="bottom">
              Corresponde al monto de la cuota mensual que tendrá que pagar
            </v-tooltip>
          </div>
        </v-col>
        <v-col cols="6" class="border-col">
          <div
            class="text-caption text-sm-h6 text-blue-accent-1 font-weight-bold border-col"
          >
            Interés: {{ formatearNumeroComoPorcentaje(oferta.tasa_mensual) }}
            <v-tooltip activator="parent" location="bottom">
              Corresponde al interes mensual del crédito
            </v-tooltip>
          </div> </v-col
        ><v-col cols="6" class="border-col">
          <div
            class="text-caption text-sm-h6 text-blue-accent-1 font-weight-bold border-col"
          >
            CAE: {{ formatearNumeroComoPorcentaje(oferta.cae) }}
          </div>
        </v-col>
        <v-col cols="12" class="border-col">
          <div
            class="text-caption text-sm-h6 text-blue-accent-1 font-weight-bold border-col"
          >
            Primera cuota: {{ formatoFechaTXT(oferta.fecha_primera_cuota) }}
          </div>
        </v-col>
        <v-col
          cols="12"
          class="text-caption text-medium-emphasis align-center border-col d-flex justify-space-between"
        >
          Ejecutivo: {{ oferta.ejecutivo }}
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>

  <v-dialog v-model="dialog" width="auto" persistent>
    <v-card
      max-width="400"
      prepend-icon="mdi-chat-question-outline"
      text="¿Está seguro que desea aceptar esta oferta? Será enviada una notificación el ejecutivo para informar e iniciar el proceso de evaluación."
      title="Aceptar condiciones"
    >
      <template v-slot:actions>
        <v-btn class="ms-auto" text="Si" @click="envioAceptacionOferta"></v-btn>
        <v-btn class="ms-auto" text="No" @click="cancelaAceptacion"></v-btn>
      </template>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  paletColor,
  formatoMonedaChile,
  formatearNumeroComoPorcentaje,
  formatoFechaTXT,
  enviarJsonPOST,
} from "@/helpers/funcionesEstandard";

export default {
  data: () => ({
    dialog: false,
    thisclass: "",
    ofertaAceptada: null,
    formatearNumeroComoPorcentaje: formatearNumeroComoPorcentaje,
    formatoFechaTXT: formatoFechaTXT,
    paletColor: paletColor,
  }),
  props: {
    oferta: Array,
  },
  mounted: function () {
    this.thisclass = "";
    if (this.oferta) {
      switch (this.oferta.estado_oferta) {
        case "ACEPTADA":
          this.thisclass += "mx-auto bg-green-lighten-4";
          break;
        case "RECHAZADA ":
          this.thisclass += "mx-auto bg-pink-lighten-5";
          break;
        default:
          this.thisclass += "mx-auto cursor-pointer ";
          break;
      }
    }
  },
  components: {},
  methods: {
    formatoMonedaChile: function (number) {
      return formatoMonedaChile(number, 0);
    },
    aceptarOferta: function (obj) {
      if (this.oferta.estado_oferta == "OFERTADO") {
        this.dialog = true;
        this.ofertaAceptada = obj;
      }
    },
    cancelaAceptacion: function () {
      this.dialog = false;
      this.ofertaAceptada = null;
    },
    envioAceptacionOferta: async function () {
      this.setFullLoading(true,'Aceptando oferta e informando a ejecutivo.');
      this.dialog = false;
      if (this.ofertaAceptada) {
        if (this.ofertaAceptada.estado_oferta == "OFERTADO") {
          let fd = new FormData();
          fd.append("solicitud", this.ofertaAceptada.solicitud_credito_id);
          fd.append("oferta", this.ofertaAceptada.id);
          var json = await enviarJsonPOST("creditoConsumo/aceptarOferta", fd);

          if (json.error == 0) {
            this.$emit("cargarInformacion");
          } else {
            this.validarMensaje(json);
          }
        } else {
          let msg = {
            error: 1,
            message:
              "La oferta ya fue aceptada y no se puede volver a enviar aceptación.",
          };
          this.validarMensaje(msg);
        }
      }
      this.setFullLoading(false,null);
    },

    validarMensaje: function (msgAjax) {
      this.$emit("validarMensaje", msgAjax);
    },
    setEjecutivo: function (usuario) {
      this.$emit("setEjecutivo", usuario);
    },
    setUsuario: function (usuario) {
      this.$emit("setUsuario", usuario);
    },
    setFullLoading: function (visible, texto) {
      this.$emit("setFullLoading", visible, texto);
    },
    SalirSistema: function () {
      this.$emit("SalirSistema");
    },
  },
};
</script>

<style>
</style>