<template>
  <v-btn
    :style="'background-color: '+paletColor.botonCancel1+';color: '+paletColor.letra1+';'"
    dark
    block
    title
    @click="cerrar(0)"
    :loading="validating"
    >{{ label }}</v-btn
  >
</template>

<script>
import {paletColor} from "@/helpers/funcionesEstandard";
export default {
  data: () => ({
    label: 'Cerrar',
    paletColor: {
      fondoPincipal: paletColor.fondoPincipal,
      fondoGeneral: paletColor.fondoGeneral,
      fondoPagina: paletColor.fondoPagina,
      letra1: paletColor.letra1,
      letra2: paletColor.letra2,
      botonSubmit1: paletColor.botonSubmit1,
      botonSubmit2: paletColor.botonSubmit2,
      botonCancel1: paletColor.botonCancel1,
      botonCancel2: paletColor.botonCancel2,
    },
  }),
  props: {
    validating: Boolean,
    labelSet: String
  },
  watch: {
    labelSet: function(val){
      this.laber = val;
    }
  },
  mounted: function(){
    if(this.labelSet){
      this.label = this.labelSet;
    }
  },
  methods: {
    cerrar: function (id) {
      this.$emit("cerrar", id);
      this.accion();
    },
    accion: function () {
      this.$emit("accion");
    },
  },
};
</script>

<style>
</style>