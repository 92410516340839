<template>
  <v-card class="mx-auto my-4 rounded-xl elevation-3">
    <v-row no-gutters align="center" justify="center">
      <v-col cols="12" md="10">
        <v-card-title
          class="rounded-be-xl"
          :style="
            'background-color:' +
            paletColor.fondoPincipal +
            ';color: ' +
            paletColor.letra2 +
            ';'
          "
        >
          <div>
            <div class="headline">{{ nombre }} - ({{ TipoCredito }})</div>
          </div>
        </v-card-title>
        <v-card-subtitle>
          <div class="subtitle-1">{{ descripcion }}</div>
        </v-card-subtitle>

        <v-card-text>
          <v-row>
            <v-col cols="12" md="6" class="mb-2 border-col">
              <v-sheet class="pa-2" color="white" rounded>
                <v-row align="center">
                  <v-icon class="mr-2" color="blue-grey-darken-1"
                    >mdi-currency-usd</v-icon
                  >
                  <div class="headline" style="color: black">
                    Monto Solicitado: {{ formatoMonedaChile(monto_solicitado) }}
                  </div>
                </v-row>
              </v-sheet>
            </v-col>
            <v-col cols="12" md="4" class="border-col text-h6">
              <v-icon class="mr-2" color="blue-grey-darken-1">mdi-cash</v-icon>
              <span style="color: black"
                >Sueldo: {{ formatoMonedaChile(sueldo) }}</span
              >
            </v-col>
            <v-col cols="12" class="border-col">
              <v-row align="center" class="text-caption">
                <v-col cols="6">
                  <v-icon class="mr-2" color="blue-grey-darken-1"
                    >mdi-bank</v-icon
                  >
                  <span style="color: black"
                    >Deuda Mensual:
                    {{ formatoMonedaChile(deuda_mensual) }}</span
                  >
                </v-col>
                <v-col cols="6">
                  <v-icon class="mr-2" color="blue-grey-darken-1"
                    >mdi-bank</v-icon
                  >
                  <span style="color: black"
                    >Deuda Total: {{ formatoMonedaChile(deuda_total) }}</span
                  >
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" md="6" class="mb-2 border-col">
              <v-sheet class="pa-2" color="white" rounded>
                <v-row align="center">
                  <v-icon class="mr-2" color="blue-grey-darken-1"
                    >mdi-calendar</v-icon
                  >
                  <span style="color: black"
                    >Fecha Primera Cuota:
                    {{ formatDate(fecha_primera_cuota) }}</span
                  >
                </v-row>
              </v-sheet>
            </v-col>
            <v-col cols="12" md="6" class="mb-2 border-col">
              <v-sheet class="pa-2" color="white" rounded>
                <v-row align="center">
                  <v-icon class="mr-2" color="blue-grey-darken-1"
                    >mdi-calendar</v-icon
                  >
                  <span style="color: black"
                    >Número de Cuotas: {{ numero_cuotas }}</span
                  >
                </v-row>
              </v-sheet>
            </v-col>
          </v-row>
          <v-divider class="my-3"></v-divider>
          <v-row>
            <v-col cols="12" sm="4" class="mb-2 border-col">
              <v-sheet class="pa-2" color="white" rounded>
                <v-row align="center">
                  <v-icon class="mr-2" color="blue-grey-darken-1"
                    >mdi-check</v-icon
                  >
                  <span style="color: black"
                    >Seguro Desgravament:
                    {{ seguro_desgravament ? "Sí" : "No" }}</span
                  >
                </v-row>
              </v-sheet>
            </v-col>
            <v-col cols="12" sm="4" class="mb-2 border-col">
              <v-sheet class="pa-2" color="white" rounded>
                <v-row align="center">
                  <v-icon class="mr-2" color="blue-grey-darken-1"
                    >mdi-check</v-icon
                  >
                  <span style="color: black"
                    >Seguro Cesantía: {{ seguro_cesantia ? "Sí" : "No" }}</span
                  >
                </v-row>
              </v-sheet>
            </v-col>
            <v-col cols="12" sm="4" class="mb-2 border-col">
              <v-sheet class="pa-2" color="white" rounded>
                <v-row align="center">
                  <v-icon class="mr-2" color="blue-grey-darken-1"
                    >mdi-check</v-icon
                  >
                  <span style="color: black"
                    >Seguro Incapacidad:
                    {{ seguro_incapacidad ? "Sí" : "No" }}</span
                  >
                </v-row>
              </v-sheet>
            </v-col>
          </v-row>
          <v-divider class="my-3 border-col"></v-divider>
          <v-row>
            <v-col cols="12">
              <div class="subtitle-2" style="color: black">
                Fecha de Creación: {{ formatDate(fecha_creacion) }}
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-col>

      <v-col cols="12" md="2">
        <v-card-actions>
          <v-row>
            <v-col
              cols="4"
              md="12"
              lg="12"
              xl="6"
              align="center"
              justify="center"
            >
              <div :class="colorOferta" @click="verOfertas">
                Ofertas<br />
                aceptadas<br />{{ ofertasAceptadas }}
              </div>
            </v-col>
            <v-col
              cols="4"
              md="12"
              lg="12"
              xl="6"
              align="center"
              justify="center"
            >
              <div
                class="black--text text-center rounded-xl elevation-6 cursor-pointer bg-blue-grey-lighten-4"
                @click="verOfertas"
              >
                Ofertas<br />
                recibidas<br />{{ ofertas }}
              </div>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-col>
    </v-row>
  </v-card>
  <listaOfertasCredito
    :dialog="dialogListaOfertas"
    :user="user"
    :usuarioId="usuarioId"
    :idSolicitud="id"
    @validarMensaje="validarMensaje"
    @setUsuario="setUsuario"
    @setEjecutivo="setEjecutivo"
    @setFullLoading="setFullLoading"
    @SalirSistema="SalirSistema"
    @cargarSolicitudes="cargarSolicitudes"
    @cerrarListaOfertas="cerrarListaOfertas"
  />
</template>
  
  <script>
import { paletColor, formatoMonedaChile } from "@/helpers/funcionesEstandard";
import listaOfertasCredito from "@/components/Cliente/CreditoConsumo/listaOfertasCredito.vue";

export default {
  name: "CustomCard",
  data: () => ({
    dialogListaOfertas: false,
    dialogOferta: false,
    pagina: null,
    formatoMonedaChile: formatoMonedaChile,
    colorOferta:
      "black--text text-center rounded-xl elevation-6 cursor-pointer",
    paletColor: paletColor,
  }),
  props: {
    user: Object,
    usuarioId: Number,
    ejecutivo: Object,
    paletColor: Object,
    ofertasAceptadas: Number,
    ofertasPendientes: Number,
    paginaRemote: {
      type: Number,
      required: true,
    },
    id: {
      type: Number,
      required: true,
    },
    nombre: {
      type: String,
      required: true,
    },
    descripcion: {
      type: String,
      required: true,
    },
    monto_solicitado: {
      type: String,
      required: true,
    },
    numero_cuotas: {
      type: Number,
      required: true,
    },
    fecha_primera_cuota: {
      type: String,
      required: true,
    },
    seguro_desgravament: {
      type: Boolean,
      required: true,
    },
    seguro_cesantia: {
      type: Boolean,
      required: true,
    },
    seguro_incapacidad: {
      type: Boolean,
      required: true,
    },
    fecha_creacion: {
      type: String,
      required: true,
    },
    ofertas: {
      type: Number,
      required: true,
    },
    TipoCredito: {
      type: String,
      required: true,
    },
    sueldo: {
      type: Number,
      required: true,
    },
    deuda_mensual: {
      type: Number,
      required: true,
    },
    deuda_total: {
      type: Number,
      required: true,
    },
  },
  components: {
    listaOfertasCredito,
  },
  mounted: function () {
    if (this.paginaRemote) {
      this.pagina = this.paginaRemote;
    }
    if (parseInt(this.ofertasAceptadas) > 0) {
      this.colorOferta += " bg-green-lighten-3";
    } else {
      this.colorOferta += " bg-blue-grey-lighten-4";
    }
  },
  methods: {
    cerrarListaOfertas: function () {
      this.dialogListaOfertas = false;
    },
    formatDate(date) {
      const options = { year: "numeric", month: "long", day: "numeric" };
      return new Date(date).toLocaleDateString(undefined, options);
    },
    verOfertas: function () {
      console.log(this.ofertas);
      if (this.ofertas > 0) {
        this.dialogListaOfertas = true;
      } else {
        let msg = {
          error: 1,
          message: "Aún no tiene ofertas",
        };
        this.validarMensaje(msg);
      }
    },
    cargarSolicitudes: async function(){
      this.$emit("cargarSolicitudes");
    },
    actionDialogOferta: function () {
      this.dialogOferta = false;
    },
    loadItems: function () {
      this.$emit("loadItems", this.paginaRemote);
    },
    submitAction() {
      if (parseInt(this.ejecutivo.empresa_persona_estado_id) == 1000) {
        this.dialogOferta = true;
      } else {
        let msg = {
          error: 1,
          message: "Estado para validación de usuario desconocido",
        };
        if (parseInt(this.ejecutivo.empresa_persona_estado_id) != 1000) {
          msg = {
            error: 1,
            message:
              "Debe las validaciones previas para póder utilizar las funcionalidades del sistema.",
          };
        }
        this.validarMensaje(msg);
      }
    },

    validarMensaje: function (msgAjax) {
      this.$emit("validarMensaje", msgAjax);
    },
    setEjecutivo: function (usuario) {
      this.$emit("setEjecutivo", usuario);
    },
    setUsuario: function (usuario) {
      this.$emit("setUsuario", usuario);
    },
    setFullLoading: function (visible, texto) {
      this.$emit("setFullLoading", visible, texto);
    },
    SalirSistema: function () {
      this.$emit("SalirSistema");
    },
  },
};
</script>
  
  <style scoped>
.headline {
  font-weight: bold;
  font-size: 20px;
  line-height: 1.6;
}
.subtitle-1,
.subtitle-2 {
  color: gray;
}
.pa-2 {
  padding: 16px !important;
}
.ma-2 {
  margin: 8px !important;
}
.white--text {
  color: white !important;
}
.black--text {
  color: black !important;
}
</style>
  