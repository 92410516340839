<template>
  <v-card class="sticky-card" color="white">
    <v-card-title class="headline" style="color: black"> Filtros </v-card-title>
    <!--v-card-subtitle class="subtitle-1" style="color: black">
          Subtítulo de la Tarjeta
        </v-card-subtitle-->
    <v-card-text>
      Monto solicitado
      <numeroVue labelSet="desde" ref="montoDesde" />
      <numeroVue labelSet="hasta" ref="montoHasta" />
      Sueldo
      <numeroVue labelSet="desde" ref="sueldoDesde" />
      <numeroVue labelSet="hasta" ref="sueldoHasta" />
    </v-card-text>
    <v-card-actions align="center" justify="center">
      <v-btn prependIcon="mdi-filter-off-outline"
       :style="'background-color: '+paletColor.botonCancel1+';color:'+paletColor.letra1+';'"
       @click="limpiarfiltrar"
        >Limpiar</v-btn
      >
      <v-spacer />
      <v-btn prependIcon="mdi-filter-outline" @click="filtrar" :style="'background-color: '+paletColor.botonSubmit1+';color:'+paletColor.letra2+';'">Filtrar</v-btn>
    </v-card-actions>
  </v-card>
</template>
  
  <script>
import { paletColor } from "@/helpers/funcionesEstandard";
import numeroVue from "@/components/form/numero.vue";
export default {
  name: "StickyCard",
  data: () => ({
    paletColor: paletColor,
  }),
  components: {
    numeroVue,
  },
  props:{
  	user: Object,
  	usuarioId: Number,
  	ejecutivo: Object,
  	ejecutivoId: Number,
  },
  methods: {
    filtrar: function () {
      let filtros = {
        montoDesde: this.$refs.montoDesde.valor,
        montoHasta: this.$refs.montoHasta.valor,
        sueldoDesde: this.$refs.sueldoDesde.valor,
        sueldoHasta: this.$refs.sueldoHasta.valor,
      };
      this.$emit("loadItems", 1, filtros);
    },
    limpiarfiltrar: function () {
      this.$refs.montoDesde.valor = null;
      this.$refs.montoHasta.valor = null;
      this.$refs.sueldoDesde.valor = null;
      this.$refs.sueldoHasta.valor = null;
      this.$emit("loadItems", 1, null);
    },
    validarMensaje: function (msgAjax) {
      this.$emit("validarMensaje", msgAjax);
    },
    setEjecutivo: function (usuario) {
      this.$emit("setEjecutivo", usuario);
    },
    setUsuario: function (usuario) {
      this.$emit("setUsuario", usuario);
    },
    setFullLoading: function (visible, texto) {
      this.$emit("setFullLoading", visible, texto);
    },
    SalirSistema: function () {
      this.$emit("SalirSistema");
    },
  },
};
</script>
  
  <style scoped>
.sticky-card {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 150px;
  z-index: 1000; /* Asegúrate de que la tarjeta esté sobre otros elementos */
}
</style>
  