<template>
  <v-card
    class="rounded-xl align-center justify-center"
    :height="heightOptions"
    @click="registraExecutive"
  >
    <v-card-text class="text-center text-h6">
      Soy Ejecutivo
      <v-card class="rounded-xl mb-3"
          :style="'background-size: '+(isMobile?'110%':'160%')+';background-position: center;background-repeat: no-repeat;'
          +'height: 200px;background-image: url(\'/img/start/loginejecutivo.jpg\''">
      </v-card>
    </v-card-text>
  </v-card>

  <div class="text-md-center pa-2">
    <v-dialog v-model="dialog" transition="dialog-top-transition" fullscreen persistent>
      <v-card>
        <v-toolbar  :style="'background-color:'+paletColor.fondoPincipal+';color: '+paletColor.letra2+';'">
          <v-btn icon="mdi-close" @click="cerrar"></v-btn>

          <v-toolbar-title>
            <span class="text-h5 display-1 font-weight-bold">{{ title }}</span>
          </v-toolbar-title>

          <v-spacer></v-spacer>
        </v-toolbar>

        <v-container class="bg-blue-lighten-5 mt-2">
          <div class="text-h5">Registro de ejecutivo comercial</div>
          <div class="text-caption">
            <div class="text-center text-caption">
              Registrate como ejecutivo comercial y podrás acceder a una gran
              gama de personas que están necesitando un crédito.
              <br />Revisa las solcicitudes existentes y podrás ofertar opcione
              de crédito.
            </div>
            <v-form
              ref="formNew"
              v-model="formNew.validForm"
              @submit.prevent="submitNew"
            >
              <v-row align="center" justify="center">
                <v-col cols="12" class="mt-8">
                  <v-row>
                    <v-col cols="6" class="border-col">
                      <email
                        :autofocusSet="true"
                        labelSet="Email"
                        :requeridoSet="true"
                        :validatingSet="formNew.validating"
                        ref="formNewEmail"
                        :focus="focusFormEmail"
                      ></email>
                    </v-col>
                    <v-col cols="6" class="border-col">
                      <comboboxVue
                        labelSet="Entidad Bancaria"
                        :items="bancos"
                        textoAlternativo="corresponde a la entidad bancaria donde trabajas"
                        :requeridoSet="true"
                        :validatingSet="formNew.validating"
                        ref="formNewBanco"
                      ></comboboxVue>
                    </v-col>
                    <v-col cols="12" sm="4" class="border-col">
                      <rut
                        labelSet="RUT"
                        :maxLengthSet="11"
                        :minLengthSet="8"
                        :requeridoSet="true"
                        :validatingSet="formNew.validating"
                        ref="formNewRUT"
                      ></rut>
                    </v-col>
                    <v-col cols="12" sm="8" class="border-col">
                      <texto
                        iconoSet="mdi-account-outline"
                        labelSet="Nombres"
                        :maxLengthSet="50"
                        :requeridoSet="true"
                        MensajeAyudaSet="Ingrese su nombre"
                        textoAlternativoSet="Nombres"
                        :validatingSet="formNew.validating"
                        ref="nombres"
                      ></texto>
                    </v-col>

                    <v-col cols="12" sm="6" class="border-col">
                      <texto
                        iconoSet="mdi-account-box-outline"
                        labelSet="Apellido paterno"
                        :maxLengthSet="50"
                        :requeridoSet="true"
                        MensajeAyudaSet="Ingrese su apellido paterno"
                        textoAlternativoSet="Apellido paterno"
                        :validatingSet="formNew.validating"
                        ref="app"
                      ></texto>
                    </v-col>
                    <v-col cols="12" sm="6" class="border-col">
                      <texto
                        iconoSet="mdi-account-box-outline"
                        labelSet="Apellido materno"
                        :maxLengthSet="50"
                        :requeridoSet="true"
                        MensajeAyudaSet="Ingrese su apellido materno"
                        textoAlternativoSet="Apellido materno"
                        :validatingSet="formNew.validating"
                        ref="apm"
                      ></texto>
                    </v-col>
                    <v-col cols="12">
                      Al registrarse, dado tu correo electrónico el sistema
                      detectara a que entidad financiera perteneces.
                      <br />Debes ingresar una cuenta de correo válida, la
                      validación de correo es necesaria para continuar con el
                      proceso.
                      <div class="border-thin pa-3 rounded-lg mt-4">
                        <span class="font-weight-medium">Email: </span>
                        <span class="text-medium-emphasis"
                          >Es necesario para identificarte como usuario ademas
                          de identificar la entidad financiera a la que
                          perteneces.</span
                        >
                        <br /><span class="font-weight-medium"
                          >RUT y nombre:
                        </span>
                        <span class="text-medium-emphasis"
                          >Son de suma importancia para poder identificarte como
                          persona en el sistema.</span
                        >
                      </div>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" md="6">
                  <formbtnsend
                    labelSet="Registrar"
                    :validating="formNew.validating"
                  ></formbtnsend>
                </v-col>
                <v-col cols="12" md="6">
                  <buttonCancelformVue
                    labelSet="Calcelar"
                    @cerrar="cerrar"
                    :validating="formNew.validating"
                  ></buttonCancelformVue>
                </v-col>
              </v-row>
            </v-form>
          </div>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { paletColor,enviarJsonPOST,obtenerJsonGET } from "@/helpers/funcionesEstandard";
import email from "@/components/form/email.vue";
import rut from "@/components/form/rut.vue";
import texto from "@/components/form/textos.vue";
import formbtnsend from "@/components/form/buttonSubmitform.vue";
import buttonCancelformVue from "@/components/form/buttonCancelform.vue";
import comboboxVue from '@/components/form/combobox.vue';

import {
  rulesRut,
  rulesMail,
  rulesName,
  rulesApellido,
  rulesEmpresa,
  rulesClave,
  rulesNameCredito,
  rulesMonedaMayIgCero,
  rulesSueldo,
  rulesMontoCredConsumo,
  rulesCuotasConsumo,
} from "@/helpers/funcionesEstandard";

export default {
  data: () => ({
    paletColor: paletColor,
    dialog: false,
    icon: "account-check",
    title: "Registro de ejecutivo comercial",
    heightOptions: 250,
    focusFormEmail: false,
    formNew: {
      validForm: null,
      validating: null,
      rutUsuario: null,
      email: null,
      nombres: null,
      app: null,
      apm: null,
    },
    bancos: [],
    rulesMail: rulesMail,
    rulesRut: rulesRut,
    rulesName: rulesName,
    rulesApellido: rulesApellido,
    rulesEmpresa: rulesEmpresa,
    rulesClave: rulesClave,
    rulesNameCredito: rulesNameCredito,
    rulesMonedaMayIgCero: rulesMonedaMayIgCero,
    rulesSueldo: rulesSueldo,
    rulesMontoCredConsumo: rulesMontoCredConsumo,
    rulesCuotasConsumo: rulesCuotasConsumo,
    fechaMasSeisMeses: null,
  }),
  props: {
  	user: Object,
  	usuarioId: Number,
  	ejecutivo: Object,
  	ejecutivoId: Number,
    heightWindow: Number,
    widthWindow: Number,
    isMobile: Boolean,
  },
  components: {
    email,
    rut,
    texto,
    formbtnsend,
    buttonCancelformVue,
    comboboxVue
  },
  mounted: function(){
    this.obtenerancos();
  },
  methods: {
    registraExecutive: function () {
      this.dialog = true;
    },
    obtenerancos: async function(){
      var resp = await obtenerJsonGET(
          "empresa/lista_combobox"
        );
        if (resp.error == 0) {
          this.bancos = resp.data;
        } else {
          this.validarMensaje(resp);
        }
    },
    submitNew: async function () {
      this.formNew.validating = true;
      const { valid } = await this.$refs.formNew.validate();
      if (valid) {
        let fd = new FormData();
        this.formNew.email = this.$refs.formNewEmail.valor;
        this.formNew.rutUsuario = this.$refs.formNewRUT.valor;
        this.formNew.nombres = this.$refs.nombres.valor;
        this.formNew.app = this.$refs.app.valor;
        this.formNew.apm = this.$refs.apm.valor;
        fd.append("rutUsuario", this.formNew.rutUsuario);
        fd.append("email", this.formNew.email);
        fd.append("nombres", this.formNew.nombres);
        fd.append("app", this.formNew.app);
        fd.append("apm", this.formNew.apm);

        var json = await enviarJsonPOST(
          "usuarios/crear_nueva_cuenta_banco",
          fd
        );

        if (json.error == 0) {
          this.dialog = false;
          this.$emit("closeRegister");
          sessionStorage.setItem("emailValid", this.$refs.formNewEmail.valor);
          await this.$router.push("/validMailExec/");
        } else {
          await this.$emit("validarMensaje", json);
        }
      }
      this.formNew.validating = false;
    },
    cerrar: function () {
      this.dialog = false;
      this.$emit("cerrar");
    },
    validarMensaje: function (msgAjax) {
      this.$emit("validarMensaje", msgAjax);
    },
    setEjecutivo: function (usuario) {
      this.$emit("setEjecutivo", usuario);
    },
    setUsuario: function (usuario) {
      this.$emit("setUsuario", usuario);
    },
    setFullLoading: function (visible, texto) {
      this.$emit("setFullLoading", visible, texto);
    },
    SalirSistema: function () {
      this.$emit("SalirSistema");
    },
  },
};
</script>

<style>
</style>