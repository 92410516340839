<template>
  <div class="text-md-center pa-2">
    <v-dialog
      v-model="dialogOn"
      transition="dialog-top-transition"
      max-width="600"
      min-width="400"
      width="80%"
      persistent
    >
      <v-card>
        <v-toolbar
          :style="
            'background-color:' +
            paletColor.fondoPincipal +
            ';color: ' +
            paletColor.letra2 +
            ';'
          "
        >
          <v-btn icon="mdi-close" @click="cerrar"></v-btn>

          <v-toolbar-title>
            <span class="text-h5 display-1 font-weight-bold">{{ title }}</span>
          </v-toolbar-title>

          <v-spacer></v-spacer>
        </v-toolbar>

        <v-container class="bg-blue-lighten-5 mt-2 text-center">
          <div class="text-h5">¡Bienvenido!</div>
          <div class="text-caption">Un texto llamativo</div>
          <div>
            <v-row>
              <v-col cols="12" md="6">
                <loginClientVue
                  :user="user"
                  :usuarioId="usuarioId"
                  :ejecutivo="ejecutivo"
                  :ejecutivoId="ejecutivoId"
                  :heightWindow="widthWindow"
                  :widthWindow="widthWindow"
                  :isMobile="isMobile"
                  @validarMensaje="validarMensaje"
                  @setEjecutivo="setEjecutivo"
                  @setUsuario="setUsuario"
                  @setFullLoading="setFullLoading"
                  @SalirSistema="SalirSistema"
                  @cerrar="cerrar"
                ></loginClientVue>
              </v-col>
              <v-col cols="12" md="6">
                <loginExecutiveVue
                  :user="user"
                  :usuarioId="usuarioId"
                  :ejecutivo="ejecutivo"
                  :ejecutivoId="ejecutivoId"
                  :heightWindow="widthWindow"
                  :widthWindow="widthWindow"
                  :isMobile="isMobile"
                  @validarMensaje="validarMensaje"
                  @setEjecutivo="setEjecutivo"
                  @setUsuario="setUsuario"
                  @setFullLoading="setFullLoading"
                  @SalirSistema="SalirSistema"
                  @cerrar="cerrar"
                ></loginExecutiveVue>
              </v-col>
            </v-row>
          </div>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { paletColor } from "@/helpers/funcionesEstandard";
import loginExecutiveVue from "@/components/Banca/Seguridad/loginExecutive.vue";
import loginClientVue from "@/components/Cliente/Seguridad/loginClient.vue";

export default {
  data: () => ({
    dialogOn: false,
    icon: "account-arrow-right",
    title: "Iniciar Sesión",
    heightOptions: 200,
    paletColor: paletColor,
  }),
  components: {
    loginClientVue,
    loginExecutiveVue,
  },
  props: {
    user: Object,
    usuarioId: Number,
    ejecutivo: Object,
    ejecutivoId: Number,
    dialog: Boolean,
    heightWindow: Number,
    widthWindow: Number,
    isMobile: Boolean,
  },
  watch: {
    dialog: function (val) {
      this.dialogOn = val;
    },
  },
  methods: {
    cerrar: function () {
      this.$emit("cerrarAccion", "iniciaSesion");
    },
    ingresaCliente: function () {
      alert("Ingresa como Cliente");
    },
    ingresaEjecutivo: function () {
      alert("ingresa como Ejecutivo Bancario");
    },
    validarMensaje: function (msgAjax) {
      this.$emit("validarMensaje", msgAjax);
    },
    setEjecutivo: function (usuario) {
      this.$emit("setEjecutivo", usuario);
    },
    setUsuario: function (usuario) {
      this.$emit("setUsuario", usuario);
    },
    setFullLoading: function (visible, texto) {
      this.$emit("setFullLoading", visible, texto);
    },
    SalirSistema: function () {
      this.$emit("SalirSistema");
    },
  },
};
</script>

<style>
</style>