<template>
  <v-row class="ml-6 mr-2 mb-8 mt-5">
    <v-col cols="12">
      <h3 class="text-center">¿En que te ayudamos hoy {{ user.name }}?</h3>
      <div class="text-center text-caption mt-3">
        Solicita un nuevo crédito
        <br />¡serás tu quiene escoge!
      </div>
    </v-col>
    <v-col cols="6" sm="6">
      <v-img
        class="cursor-pointer"
        :width="100"
        aspect-ratio="16/9"
        cover
        src="/img/credito_consumo.png"
        @click="opcionRapida('CRECO')"
      ></v-img>
    </v-col>
    <v-col cols="6" sm="6">
      <v-img
        :width="100"
        aspect-ratio="16/9"
        cover
        src="/img/credito_hipotecario.png"
      ></v-img>
    </v-col>
    <v-col cols="6" sm="6">
      <v-img
        :width="100"
        aspect-ratio="16/9"
        cover
        src="/img/credito_automotriz.png"
      ></v-img>
    </v-col>
    <v-col cols="6" sm="6">
      <v-img
        :width="100"
        class="cursor-pointer"
        aspect-ratio="16/9"
        cover
        src="/img/credito_Salir.png"
        @click="SalirSistema()"
      ></v-img>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    user: String,
  },
  methods:{
    opcionRapida: function(codigo){
        switch(codigo){
            case 'CRECO':{
                this.$router.push("/nuevoCreditoConsumo");
            }
        }
    },
    validarMensaje: function (msgAjax) {
      this.$emit("validarMensaje", msgAjax);
    },
    setUsuario: function (usuario) {
      this.$emit("setUsuario", usuario);
    },
    setFullLoading: function (visible, texto) {
      this.$emit("setFullLoading", visible, texto);
    },
    SalirSistema: function () {
      this.$emit("SalirSistema");
    },
  }
};
</script>