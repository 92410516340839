<template>
  <v-app>
    <v-app-bar app :style="'background-color:' + paletColor.fondoGeneral + ';'">
      <!-- Logo Title -->
      <!-- Left Navigation Button -->
      <v-app-bar-nav-icon
        v-bind="attrs"
        v-on="on"
        @click="drawer2 = !drawer2"
        v-if="isMobile || user.usuarioId || ejecutivo.usuarioId"
      />

      <v-toolbar-title
        width="150px"
        class="ma-0 pa-0"
        align="left"
        justify="left"
      >
        <v-img src="/img/News/LogoInvertido.png" height="50px" width="50%" />
      </v-toolbar-title>

      <!-- Menu Links for Desktop -->
      <v-spacer></v-spacer>
      <v-btn
        v-for="link in links"
        :key="link.text"
        :prepend-icon="link.icon"
        text
        v-show="!isMobile"
        @click="ejecutarAccon(link.action)"
        :style="
          'background-color:' + link.colorback + ';color: ' + link.color + ';'
        "
        v-if="!user.usuarioId && !ejecutivo.usuarioId"
      >
        {{ link.text }}
      </v-btn>
    </v-app-bar>
    <!-- Drawer Menu right-->
    <v-navigation-drawer
      temporary
      v-model="drawer2"
      :style="'background-color:' + paletColor.fondoGeneral + ';'"
    >
      <!--template v-slot:prepend>
        <v-list-item
          lines="two"
          prepend-avatar="https://randomuser.me/api/portraits/women/81.jpg"
          subtitle="Logged in"
          :title="ejecutivo.name"
        ></v-list-item>
      </template-->

      <v-divider></v-divider>

      <v-list density="compact" nav>
        <v-list-item
          v-for="link in links"
          :key="link"
          :id="link.action"
          :prepend-icon="link.icon"
          :title="link.text"
          @click="ejecutarAccon(link.action)"
          :style="
            'background-color:' + link.colorback + ';color: ' + link.color + ';'
          "
          v-if="!user.usuarioId && !ejecutivo.usuarioId"
        ></v-list-item>
        <v-list-item
          title="Crédito de consumo"
          @click="ejecutarAccon('nuevoConsumo')"
          v-if="user.usuarioId || ejecutivo.usuarioId"
        >
        </v-list-item>
        <v-list-item
          title="Salir"
          @click="SalirSistema()"
          :style="
            'background-color:' +
            paletColor.botonSubmit1 +
            ';color: ' +
            paletColor.letra2 +
            ';'
          "
          v-if="user.usuarioId || ejecutivo.usuarioId"
        >
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <!-- Main content -->
    <v-main :style="'background-color:' + paletColor.fondoGeneral + ';'">
      <router-view
        :paletColor="paletColor"
        :isMobile="isMobile"
        :linkAction="linkAction"
        :user="user"
        :usuarioId="usuarioId"
        :ejecutivo="ejecutivo"
        :ejecutivoId="ejecutivoId"
        :heightWindow="heightWindow"
        :widthWindow="widthWindow"
        :dialogNuevoConsumo="dialogNuevoConsumo"
        @validarMensaje="validarMensaje"
        @setEjecutivo="setEjecutivo"
        @setUsuario="setUsuario"
        @setFullLoading="setFullLoading"
        @SalirSistema="SalirSistema"
        @ejecutarAccon="ejecutarAccon"
        @cerrarAccion="cerrarAccion"
      />
      <v-footer align="right" class="text-caption"
        >Entorno ({{ entorno }}) - Versión ({{ version }}) - &#169; CrediGO
        Chile SPA</v-footer
      >
    </v-main>
    <loginUserVue
      :dialog="dialogLoginUser"
      :paletColor="paletColor"
      :user="user"
      :usuarioId="usuarioId"
      :ejecutivo="ejecutivo"
      :ejecutivoId="ejecutivoId"
      :heightWindow="heightWindow"
      :widthWindow="widthWindow"
      :isMobile="isMobile"
      @validarMensaje="validarMensaje"
      @setEjecutivo="setEjecutivo"
      @setUsuario="setUsuario"
      @setFullLoading="setFullLoading"
      @SalirSistema="SalirSistema"
      @cerrarAccion="cerrarAccion"
    />
    <registerUserVue
      :dialog="dialogRegisterUser"
      :paletColor="paletColor"
      :user="user"
      :usuarioId="usuarioId"
      :ejecutivo="ejecutivo"
      :ejecutivoId="ejecutivoId"
      :heightWindow="heightWindow"
      :widthWindow="widthWindow"
      :isMobile="isMobile"
      @validarMensaje="validarMensaje"
      @setEjecutivo="setEjecutivo"
      @setUsuario="setUsuario"
      @setFullLoading="setFullLoading"
      @SalirSistema="SalirSistema"
      @cerrarAccion="cerrarAccion"
    />

    <v-dialog
      persistent
      width="auto"
      max-width="70%"
      v-if="dialogEvalMessage"
      v-model="dialogEvalMessage"
    >
      <v-card class="elevation-6 mt-10">
        <v-toolbar
          :title="msg.title"
          :style="'background-color: ' + paletColor.fondoPincipal + ';'"
          >{{ msg.code }} {{ msg.number ? " : " + msg.number : "" }}</v-toolbar
        >
        <v-divider class="mt-3"></v-divider>
        <v-card-text>
          {{ msg.text }}
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-row>
            <v-col cols="12" align="center">
              <v-btn variant="outlined" class="bg-blue" @click="cerrar"
                >Aceptar</v-btn
              >
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      persistent
      width="auto"
      v-if="fullLoading"
      v-model="fullLoading"
      class="text-center"
    >
      <v-card class="pa-5">
        <div>
          <v-progress-circular
            :size="50"
            color="primary"
            indeterminate
          ></v-progress-circular>
        </div>
        <div>
          <v-html>{{ textoFullLoading }}</v-html>
        </div>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
import { paletColor } from "@/helpers/funcionesEstandard";
import loginUserVue from "./components/Start/loginUser.vue";
import registerUserVue from "./components/Start/registerUser.vue";

export default {
  data: () => ({
    drawer: false,
    drawer2: false,
    linkAction: null,
    links: [
      { text: "Inicio", route: "/", action: "inicio", icon: "mdi-home-circle" },
      {
        text: "Cómo funciona",
        route: "/how-it-works",
        action: "comoFunciona",
        icon: "mdi-sitemap",
      },
      {
        text: "Sobre CrediGO",
        route: "/about",
        action: "SobreCredigo",
        icon: "mdi-account-group",
      },
      {
        text: "FAQs",
        route: "/faqs",
        action: "FAQs",
        icon: "mdi-chat-question",
      },
      {
        text: "Registro",
        route: "/register",
        action: "registrar",
        icon: "mdi-account-check",
        color: paletColor.letra2,
        colorback: paletColor.botonSubmit1,
      },
      {
        text: "Inicio de sesión",
        route: null,
        action: "iniciaSesion",
        icon: "mdi-account-arrow-right-outline",
      },
    ],
    actuaInicioSesion: false,
    isMobile: false,
    fullLoading: false,
    dialogEvalMessage: false,
    paletColor: paletColor,
    user: {
      token: null,
      name: null,
      usuarioId: null,
    },
    ejecutivo: {
      token: null,
      name: null,
      usuarioId: null,
    },
    entorno: process.env.NODE_ENV,
    version: process.env.VUE_APP_VERSION,
    msg: {
      number: null,
      code: null,
      title: null,
      text: null,
    },
    dialogLoginUser: false,
    dialogRegisterUser: false,
    heightWindow: false,
    widthWindow: false,
    dialogNuevoConsumo: false,
  }),

  components: {
    loginUserVue,
    registerUserVue,
  },
  mounted() {
    if (sessionStorage.getItem("ejecutivo") || sessionStorage.getItem("user")) {
      if (sessionStorage.getItem("ejecutivo")) {
        this.ejecutivo = JSON.parse(sessionStorage.getItem("ejecutivo"));
      }
      if (sessionStorage.getItem("user")) {
        this.user = JSON.parse(sessionStorage.getItem("user"));
      }
    }
    this.checkScreenSize();
    window.addEventListener("resize", this.checkScreenSize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.checkScreenSize);
  },
  methods: {
    ejecutarAccon: function (accion) {
      this.drawer2 = false;
      switch (accion) {
        case "inicio":
          this.linkAction = accion + "Div";
          this.$router.push("/");
          break;
        case "inicio":
          this.$router.push("/");
          break;
        case "iniciaSesion":
          this.dialogLoginUser = true;
          break;
        case "registrar":
          this.dialogRegisterUser = true;
          break;
        case "nuevoConsumo":
          this.dialogNuevoConsumo = true;
          break;
        default:
          this.linkAction = accion + "Div";
          break;
      }
    },
    cerrarAccion: function (accion) {
      this.drawer2 = false;
      switch (accion) {
        case "iniciaSesion":
          this.dialogLoginUser = false;
          break;
        case "registrar":
          this.dialogRegisterUser = false;
          break;
        case "cerrarNuevoConsumo":
          this.dialogNuevoConsumo = false;
          break;
      }
    },
    navigate(route) {
      this.$router.push(route);
    },
    checkScreenSize() {
      this.isMobile = window.innerWidth <= 600;
      this.widthWindow = window.innerWidth;
      this.widthWindow = window.innerWidth;
    },

    setUsuario: async function (usuario) {
      if (typeof usuario == "object" && usuario != null) {
        this.user.token = usuario.token;
        this.user.name = usuario.nombre;
        this.user.usuarioId = usuario.id;
        const parsed = JSON.stringify(this.user);
        sessionStorage.setItem("user", parsed);
      } else {
        sessionStorage.setItem("user", null);
        sessionStorage.removeItem("user");
      }
    },
    setEjecutivo: async function (usuario) {
      if (typeof usuario == "object" && usuario != null) {
        this.ejecutivo.token = usuario.token;
        this.ejecutivo.name = usuario.nombre;
        this.ejecutivo.usuarioId = usuario.id;
        this.ejecutivo.estado_usuario = usuario.estado_usuario;
        this.ejecutivo.empresa_persona_estado_id =
          usuario.empresa_persona_estado_id;

        const parsed = JSON.stringify(this.ejecutivo);
        sessionStorage.setItem("ejecutivo", parsed);
      } else {
        sessionStorage.setItem("ejecutivo", null);
        sessionStorage.removeItem("ejecutivo");
      }
    },
    validarMensaje: async function (msgAjax) {
      if (typeof msgAjax == "object") {
        this.msg.number = msgAjax.error;
        this.msg.code = msgAjax.code;
        this.msg.title = msgAjax.title;
        this.msg.text = msgAjax.message;
      } else {
        this.msg.number = "0000";
        this.msg.code = "0000";
        this.msg.title = "ERROR INESPERADO";
        this.msg.text =
          "El mensaje de error no pudo ser interpretado correctamente, el sistema se cerrará. Por favor volver a ingresar.";
      }
      this.dialogEvalMessage = true;
    },
    cerrar: async function () {
      this.dialogEvalMessage = false;
      if (this.msg.number == 1000) {
        this.SalirSistema();
      }
    },
    setFullLoading: async function (visible, texto) {
      if (visible) {
        this.textoFullLoading = texto;
      }
      this.fullLoading = visible;
    },
    SalirSistema: function () {
      this.user = {
        token: null,
        name: null,
        usuarioId: null,
      };
      this.ejecutivo = {
        token: null,
        name: null,
        usuarioId: null,
      };
      this.setUsuario(this.user);
      this.setEjecutivo(this.ejecutivo);
      this.dialogEvalMessage = false;
      this.fullLoading = false;
      this.textoFullLoading = "";
      this.$router.push("/");
    },
  },
};

/**
  import {paletColor,enviarJsonPOST} from "@/helpers/funcionesEstandard";
  export default {
    data: () => ({
      paletColor: paletColor
    }),
    props:{
      user: Object,
      usuarioId: Number,
      ejecutivo: Object,
      ejecutivoId: Number,
      heightWindow: Number,
      widthWindow: Number,
      isMobile: Boolean,
    },
    methods: {
      validarMensaje: function (msgAjax) {
        this.$emit("validarMensaje", msgAjax);
      },
      setEjecutivo: function (usuario) {
        this.$emit("setEjecutivo", usuario);
      },
      setUsuario: function (usuario) {
        this.$emit("setUsuario", usuario);
      },
      setFullLoading: function (visible, texto) {
        this.$emit("setFullLoading", visible, texto);
      },
      SalirSistema: function () {
        this.$emit("SalirSistema");
      },
    },
  };

  :user="user"
  :usuarioId="usuarioId"
  :ejecutivo="ejecutivo"
  :ejecutivoId="ejecutivoId"
  :heightWindow="widthWindow"
  :widthWindow="widthWindow"
  :isMobile="isMobile"
  @validarMensaje="validarMensaje"
  @setEjecutivo="setEjecutivo"
  @setUsuario="setUsuario"
  @setFullLoading="setFullLoading"
  @SalirSistema="SalirSistema"
 */
</script>

<style>
/* Add any additional styling you need here */
</style>