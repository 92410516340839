<template>
  <div
    :style="'background-color: ' + paletColor.fondoPincipal + ';'"
    class="pa-6" id="inicioDiv"
  >
    <v-row align="center" justify="center">
      <v-col cols="11" md="5">
        <v-row>
          <v-col cols="12" class="ma-3 ma-md-12" align="left" justify="center">
            <v-img src="/img/News/Logo.png" :width="isMobile?'20%':'25%'" />
          </v-col>
          <v-col cols="12" :class="isMobile?'ma-2':'ma-12'" align="left" justify="center">
            <div
              :style="
                'color: ' +
                paletColor.letra2 +
                '; font-size: '+(isMobile?'300%':'400%')+'; font-family: Arial, sans-serif; font-weight: bold;'
              "
            >
              Tu dinero,<br />
              tu decisión
            </div>
            <div
              :style="
                'color: ' +
                paletColor.letra2 +
                '; font-size: '+(isMobile?'100%':'150%')+'; font-family: Arial, sans-serif; '
              "
            >
              Como siempre tuvo que haber sido,
              <br />que ellos compitan por ti
            </div>
          </v-col>
        </v-row>
      </v-col>
      <v-col
        cols="11"
        md="5"
        align="center"
        justify="center"
        class="align-self-center mt-5"
        fluid
        style="
          background-size: 70%;
          background-position: center;
          background-repeat: no-repeat;
          background-image: url('/img/start/FondoImagenes.svg');
        "
      >
        <v-row fluid>
          <v-col cols="6" class="align-self-center" fluid>
            <v-row fluid>
              <v-col cols="12" height="500px">
                <div
                  :style="'background-size: '+(isMobile?'150%':'120%')+';background-position: center;background-repeat: no-repeat;width: 100%;height: '+(isMobile?'300px':'400px')+';'+
                  'padding-bottom: 100%;background-color: lightblue;border-radius: '+(isMobile?'48%':'50%')+';display: inline-block;border: 0px;'+
                  'background-image: url(\'/img/start/imagen01.jpg\');'"
                ></div>
              </v-col>
              <v-col cols="12" fluid>
                <div
                  style="
                    background-size: 120%;
                    background-position: center;
                    background-repeat: no-repeat;
                    width: 100%;
                    height: 0;
                    padding-bottom: 100%;
                    background-color: lightblue;
                    border-radius: 50%;
                    display: inline-block;
                    border: 5px solid #fff;
                    background-image: url('https://ejemplobancatesting.my.canva.site/images/610df971e413cb3b80acf3110c026cbe.jpg');
                  "
                ></div>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="6" class="align-self-center" fluid>
            <v-row fluid>
              <v-col cols="12" fluid>
                <div
                  style="
                    background-size: 120%;
                    background-position: center;
                    background-repeat: no-repeat;
                    width: 100%;
                    height: 0;
                    padding-bottom: 100%;
                    background-color: lightblue;
                    border-radius: 50%;
                    display: inline-block;
                    border: 5px solid #fff;
                    background-image: url('https://ejemplobancatesting.my.canva.site/images/f5fc303f307f5d42e5dda254da01cc30.jpg');
                  "
                ></div>
              </v-col>
              <v-col cols="12" height="500px">
                <div
                  :style="'background-size: '+(isMobile?'150%':'120%')+';background-position: center;background-repeat: no-repeat;width: 100%;height: '+(isMobile?'300px':'400px')+';'+
                  'padding-bottom: 100%;background-color: lightblue;border-radius: '+(isMobile?'48%':'50%')+';display: inline-block;border: 0px;'+
                  'background-image: url(\'/img/start/imagen03.jpg\');'"
                ></div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
  <!--
  ***********************************************************************************************
  ***********************************************************************************************
  ACA ESTA EL SEGUNDO BLOQUE FUNCIONAMIENTO CLIENTE
  ***********************************************************************************************
  ***********************************************************************************************
  -->
  <div
    :style="'background-color: ' + paletColor.fondoGeneral + ';'"
    class="pa-6" id="comoFuncionaDiv"
  >
    <v-row align="center" justify="center">
      <v-col cols="11" align="center" justify="center">
        <v-card
          max-width="300"
          :class="(isMobile?'ma-3 pa-4':'ma-10 pa-4')+' text-h6 text-md-h4 rounded-xl elevation-6'"
          :style="
            'background-color:' +
            paletColor.fondoPincipal +
            '; color: ' +
            paletColor.letra2 +
            ';'
          "
        >
          ¿Como funciona?<br />
          Solicitante
          <buttonCancelform @accion="ejecutarAccon" :style="'background-color: '+paletColor.botonSubmit1+';'" labelSet="Registro"/>
        </v-card> 
        
        </v-col
      ><v-col
        cols="11"
        align="center"
        justify="center"
        class="text-h5 text-md-h2 font-weight-black"
      >
        El Proceso es simple
      </v-col>

      <v-col cols="12" align="center" justify="center">
        <div :max-width="isMobile?'100%':'80%'">
          <v-row cols="12" align="center" justify="center">
            <v-col cols="11" md="3">
              <v-card class="ma-4 rounded-xl elevation-6" height="220px">
                <div>
                  <v-img
                    src="/img/start/Presentate.svg"
                    height="50px"
                    width="50px"
                    class="mt-4 rounded-lg elevation-6"
                    :style="'background-color:' + paletColor.botonSubmit2 + ';'"
                  />
                </div>
                <div class="text-h6 text-md-h5 mt-4">Preséntate</div>
                <div class="my-4 text-caption">
                  En menos de 5 minutos valida tu perfil y cuentanos que crédito
                  necesitas.
                </div>
              </v-card>
            </v-col>
            <v-col cols="11" md="3">
              <v-card class="ma-4 rounded-xl elevation-6" height="220px">
                <div>
                  <v-img
                    src="/img/start/Oferta.svg"
                    height="50px"
                    width="50px"
                    class="mt-4 rounded-lg elevation-6"
                    :style="'background-color:' + paletColor.botonSubmit2 + ';'"
                  />
                </div>
                <div class="text-h6 text-md-h5 mt-4">Recibe ofertas y elige la mejor</div>
                <div class="my-4 text-caption">
                  Ejecutivos darán sus mejores ofertas para tenerte como
                  cliente.
                </div>
              </v-card>
            </v-col>
            <v-col cols="11" md="3">
              <v-card class="ma-4 rounded-xl elevation-6" height="220px">
                <div>
                  <v-img
                    src="/img/start/Acepta.svg"
                    height="50px"
                    width="50px"
                    class="mt-4 rounded-lg elevation-6"
                    :style="'background-color:' + paletColor.botonSubmit2 + ';'"
                  />
                </div>
                <div class="text-h6 text-md-h5 mt-4">
                  Formaliza tu solicitud con el ejecutivo
                </div>
                <div class="my-4 text-caption">
                  Se pondrá en contacto tu ejecutivo para formalizar la
                  solicitud.
                </div>
              </v-card>
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
  </div>
  <!--
  ***********************************************************************************************
  ***********************************************************************************************
  ACA ESTA EL SEGUNDO BLOQUE FUNCIONAMIENTO EJECUTIVO
  ***********************************************************************************************
  ***********************************************************************************************
  -->
  <div :style="'background-color: #F5E8DA;'" class="pa-6">
    <v-row align="center" justify="center">
      <v-col cols="11" align="center" justify="center">
        <v-card
          max-width="300"
          :class="(isMobile?'ma-3 pa-4':'ma-10 pa-4')+' text-h6 text-md-h4 rounded-xl elevation-6'"
          :style="
            'background-color:' +
            paletColor.fondoPincipal +
            '; color: ' +
            paletColor.letra2 +
            ';'
          "
        >
          ¿Como funciona?<br />
          Ejecutivo
          <buttonCancelform @accion="ejecutarAccon" :style="'background-color: '+paletColor.botonSubmit1+';'" labelSet="Registro"/>
        </v-card> </v-col
      ><v-col
        cols="11"
        align="center"
        justify="center"
        class="text-h5 text-md-h2 font-weight-black"
      >
        Nunca fue tan fácil
      </v-col>
      <v-col cols="12" align="center" justify="center">
        <div max-width="80%">
          <v-row cols="12" align="center" justify="center">
            <v-col cols="11" md="3">
              <v-card class="ma-4 rounded-xl elevation-6" height="220px">
                <div>
                  <v-img
                    src="/img/start/Presentate.svg"
                    height="50px"
                    width="50px"
                    class="mt-4 rounded-lg elevation-6"
                    :style="'background-color:' + paletColor.botonSubmit2 + ';'"
                  />
                </div>
                <div class="text-h6 text-md-h5 mt-4">Preséntate</div>
                <div class="my-4 text-caption">
                  En menos de 5 minutos valida tu perfil, cargo e institución.
                </div>
              </v-card>
            </v-col>
            <v-col cols="11" md="3">
              <v-card class="ma-4 rounded-xl elevation-6" height="220px">
                <div>
                  <v-img
                    src="/img/start/Oferta.svg"
                    height="50px"
                    width="50px"
                    class="mt-4 rounded-lg elevation-6"
                    :style="'background-color:' + paletColor.botonSubmit2 + ';'"
                  />
                </div>
                <div class="text-h6 text-md-h5 mt-4">Oferta a los mejores prospectos</div>
                <div class="my-4 text-caption">
                  Navega y oferta a los segmentos que necesites, de manera
                  simple y segura.
                </div>
              </v-card>
            </v-col>
            <v-col cols="11" md="3">
              <v-card class="ma-4 rounded-xl elevation-6" height="220px">
                <div>
                  <v-img
                    src="/img/start/Acepta.svg"
                    height="50px"
                    width="50px"
                    class="mt-4 rounded-lg elevation-6"
                    :style="'background-color:' + paletColor.botonSubmit2 + ';'"
                  />
                </div>
                <div class="text-h6 text-md-h5 mt-4">
                  Formaliza tu solicitud con el cliente
                </div>
                <div class="my-4 text-caption">
                  Obtienes los datos de contacto del cliente para que cierres
                  con ellos.
                </div>
              </v-card>
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
  </div>
  <!--
  ***********************************************************************************************
  ***********************************************************************************************
  ACA ESTA EL SEGUNDO BLOQUE QUIENES SOMOS
  ***********************************************************************************************
  ***********************************************************************************************
  -->
  <div
    :style="'background-color: ' + paletColor.fondoGeneral + ';'" id="SobreCredigoDiv"
    class="pa-6"
  >
    <v-row align="center" justify="center">
      <v-col
        cols="11"
        align="center"
        justify="center"
        class="text-h5 text-md-h2 font-weight-black"
      >
        SOBRE CREDIGO
      </v-col>

      <v-col cols="11" md="5" class="pa-4" align-self="center">
        <v-card
          class="pa-4 rounded-xl elevation-6"
          style="background-color: white"
        >
          <v-row align="center" justify="center">
            <v-col cols="6">
              <div
                style="
                  background-size: 120%;
                  background-position: center;
                  background-repeat: no-repeat;
                  width: 100%;
                  height: 0;
                  padding-bottom: 100%;
                  background-color: lightblue;
                  border-radius: 50%;
                  display: inline-block;
                  border: 5px solid pink;
                  background-image: url('/img/start/imagen5.jpg');
                "
              ></div>
            </v-col>
            <v-col cols="6">
              <div
                style="
                  background-size: 200%;
                  background-position: right top;
                  background-repeat: no-repeat;
                  width: 100%;
                  height: 0;
                  padding-bottom: 100%;
                  background-color: lightblue;
                  border-radius: 50%;
                  display: inline-block;
                  border: 0px;
                  background-image: url('/img/start/imagen6.jpg');
                "
              ></div>
            </v-col>
            <v-col cols="12">
              <div class="text-h6 text-md-h5 mt-4" align="left">Nuestra meta</div>
              <div class="text-caption my-4" align="left">
                Creamos un espacio para una competencia más simple y
                transparente en el sistema financiero.
              </div>
            </v-col>
          </v-row>
        </v-card>
      </v-col>

      <v-col cols="11" md="5" class="pa-4" align="center" justify="center">
        <v-row>
          <v-col cols="12">
            <v-card
              class="pa-4 rounded-xl elevation-6"
              style="background-color: white"
            >
              <v-row>
                <v-col cols="5" align-self="center">
                  <div
                  :style="'background-size: '+(isMobile?'310%':'200%')+';background-position: center;background-repeat: no-repeat;width: 100%;height: '+(isMobile?'220px':'200px')+';'+
                  'padding-bottom: 100%;background-color: lightblue;border-radius: '+(isMobile?'48%':'50%')+';display: inline-block;border: 0px;'+
                  'background-image: url(\'/img/start/imagen7.jpg\');'"
                  ></div>
                </v-col>
                <v-col cols="7" align="left">
                  <div class="text-h6 text-md-h5 mt-4" align="left">
                    Sobre nuestra plataforma
                  </div>
                  <div class="text-caption my-4" align="left">
                    Facilitamos la conexión entre individuos con ejecutivos
                    comprometidos en ofrecer soluciones financieras accesibles y
                    simples.
                  </div>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
          <v-col cols="12">
            <v-card
              class="pa-4 rounded-xl elevation-6"
              style="background-color: white"
            >
              <v-row>
                <v-col cols="5" align-self="center">
                  <div
                  :style="'background-size: '+(isMobile?'310%':'200%')+';background-position: center;background-repeat: no-repeat;width: 100%;height: '+(isMobile?'220px':'200px')+';'+
                  'padding-bottom: 100%;background-color: lightblue;border-radius: '+(isMobile?'48%':'50%')+';display: inline-block;border: 0px;'+
                  'background-image: url(\'/img/start/imagen8.jpg\');'"
                  class=""
                  ></div>
                </v-col>
                <v-col cols="7" align="left">
                  <div class="text-h6 text-md-h5 mt-4" align="left">
                    Que nos diferencia
                  </div>
                  <div class="my-4" align="left">
                    <v-list>
                      <v-list-item class="text-caption"
                        >Un mercado transparente en tus manos</v-list-item
                      >
                      <v-list-item class="text-caption"
                        >Ofertas reales de manera simple y rápida</v-list-item
                      >
                      <v-list-item class="text-caption"
                        >Puedes saber anticipadamente la eficiencia histórica de
                        los ejecutivos</v-list-item
                      >
                    </v-list>
                  </div>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
  <!--
  ***********************************************************************************************
  ***********************************************************************************************
  ACA ESTA EL PREGUNTAS FRECUENTES
  ***********************************************************************************************
  ***********************************************************************************************
  -->
  <div :style="'background-color: #F5E8DA;'" class="pa-6" id="FAQsDiv">
    <v-row align="center" justify="center">
      <v-col
        cols="11"
        align="center"
        justify="center"
        class="text-h5 text-md-h2 font-weight-black"
      >
        Preguntas Frecuentes FAQs
      </v-col>
      <v-col cols="11">
        <v-expansion-panels>
          <v-expansion-panel class="pa-4 rounded-xl elevation-6" v-for="faq in preguntasFrecuentes" :key="faq">
            <v-expansion-panel-title>
              <div class="text-h6 text-md-h5 mt-4" align="left">
                {{ faq.title }}
              </div>
            </v-expansion-panel-title>
            <v-expansion-panel-text>
              <div class="my-4 text-caption" align="left"  v-html="faq.text">
              </div>
            </v-expansion-panel-text>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { paletColor } from "@/helpers/funcionesEstandard";
import buttonCancelform from "../form/buttonCancelform.vue";
export default {
  data: () => ({
    paletColor: paletColor,
    preguntasFrecuentes: [
      {
        title:'¿Qué diferencia hay entre CrediGo y un comparador?',
        text: "En Credigo, las ofertas que recibes provienen de ejecutivos"
                +" bancarios validados, asegurando que cada propuesta esté"
                +" adaptada a tus necesidades específicas."
                +" <br /><br />"
                +" A diferencia de un comparador de créditos, que muestra"
                +" resultados aproximados basados en ofertas genéricas, Credigo te"
                +" ofrece mayor transparencia y personalización para encontrar el"
                +" financiamiento que realmente necesitas.",
      },
      {
        title:'¿Cuánto tiempo permanece activa mi solicitud?',
        text: "Tu solicitud permanecerá activa por 7 días, durante los cuales recibirás ofertas personalizadas de ejecutivos bancarios."
                +"<br><br>En cualquier momento, puedes cancelar la solicitud o elegir alguna oferta que te parezca atractiva.",
      },
      {
        title:'¿Por qué no he recibido ofertas?',
        text: "Si no has recibido ofertas, podría deberse a que tu perfil no cumple con los criterios específicos de los ejecutivos."
                +"<br><br>Te recomendamos utilizar nuestro simulador para tener una visión previa de montos y plazos factibles a tu situación."
                +" Además, comprueba que toda la información proporcionada sea precisa y completa.",
      },
      {
        title:'¿CrediGo gestiona el crédito?',
        text: "No, CrediGo no gestiona el crédito. Nuestra plataforma facilita el contacto entre personas que buscan créditos y ejecutivos de entidades financieras."
                +"<br><br>Una vez que seleccionas una oferta que te interesa, el ejecutivo se pondrá en contacto contigo para finalizar el proceso utilizando métodos tradicionales."
                +"<br><br>Nos aseguramos de que nuestra plataforma ofrezca un entorno seguro y transparente para conectar con ejecutivos confiables,"
                +"brindándote así acceso a una gama de opciones adaptadas a tus necesidades.",
      },
      {
        title:'¿Cuánto dinero me pueden pasar?',
        text: "Todo dependerá de tu perfíl financiero y capacidad de pago.",
      },
      {
        title:'¿Cómo protejen mis datos personales?',
        text: "En CrediGo, tu privacidad y seguridad son nuestra prioridad."
                +" Utilizamos cifrado de datos y protocolos de seguridad robustos para garantizar que tu información esté protegida en todo momento. "
                +"<br><br>Además, solo compartimos tus datos con ejecutivos de entidades financieras validados y solo con tu consentimiento. "
                +"<br><br>Tu información se maneja de acuerdo con las normativas de protección de datos y nunca se utiliza para fines distintos a los que tú has autorizado.",
      },
      {
        title:'¿Qué incluye una oferta?',
        text: "Cuota mensual, valor líquido del crédito, plazo, costo total , tasa de interés mensual, interéses, comisiones y CAE.",
      },
      {
        title:'¿Si acepto una oferta, ya estoy comprometido?',
        text: "Aceptar una oferta en CrediGo no te compromete inmediatamente. Una vez que aceptas una oferta, el ejecutivo se pondrá en contacto contigo para finalizar los"
                +" detalles y concretar el crédito a través de métodos tradicionales. Tienes la posibilidad de cancelar tu solicitud en cualquier momento antes de firmar el "
                +" acuerdo final si decides que no quieres seguir adelante. "
                +"<br><br>Nuestra plataforma te da flexibilidad para asegurarte de que tomes decisiones informadas.",
      },
      {
        title:'¿Es obligatorio contratar seguros?',
        text: "Para créditos de consumo, no es obligatorio contratar seguros adicionales; cualquier seguro ofrecido es opcional."
                +" Por ese motivo, en tu solicitud los puedes incorporar o no. Sin embargo, suele ser útil evaluar su incorporación para bajar el impacto de eventos inesperados."
                +" <br><br>Para créditos hipotecarios, se requiere la contratación de seguros como el de desgravamen, el de incendio y terremoto."
                +" <br><br>Siempre revisa los requisitos específicos de cada oferta y consulta con el ejecutivo si tienes dudas.",
      },
      {
        title:'¿Qué sucede si no recibo un oferta interesante?',
        text: "No hay problema. Si las ofertas que recibas no cumplen con tus expectativas, no estás obligado a aceptar ninguna."
                +"<br><br>Te ofrecemos las mejores opciones disponibles, pero si ninguna se ajusta a lo que buscas,"
                +" puedes abandonar el proceso en cualquier momento, sin ningún costo adicional. Nuestra misión es hacerte la vida más fácil.",
      },
    ]
  }),
  props: {
    user: Object,
    ejecutivo: Object,
    paletColor: Object,
    linkAction: String,
    isMobile: Boolean,
    heightWindow: Number,
    widthWindow: Number,
  },
  components: {
    buttonCancelform,
  },
  watch: {
    linkAction: function(val){
      this.scrollToDiv(val);
    }
  },
  mounted: function () {},
  methods: {
    ejecutarAccon: function(){
      console.log('llego');
      this.$emit("ejecutarAccon",'registrar');
    },
    scrollToDiv(divId) {
      const element = document.getElementById(divId);
      const headerOffset = 40;
      const elementPosition = element.getBoundingClientRect().top + window.pageYOffset;
      const offsetPosition = elementPosition - headerOffset;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth"
      });
    },
    validarMensaje: function (msgAjax) {
      this.$emit("validarMensaje", msgAjax);
    },
    setEjecutivo: function (usuario) {
      this.$emit("setEjecutivo", usuario);
    },
    setUsuario: function (usuario) {
      this.$emit("setUsuario", usuario);
    },
    setFullLoading: function (visible, texto) {
      this.$emit("setFullLoading", visible, texto);
    },
    SalirSistema: function () {
      this.$emit("SalirSistema");
    },
  },
};
</script>

<style>
</style>
