<template>
  <v-toolbar
    flat
    app
    :style="
      'background-color:' +
      paletColor.fondoPincipal +
      ';color: ' +
      paletColor.letra2 +
      ';'
    "
  >
    <v-toolbar-title>
      <v-img src="/img/ejecutivo_comercial.png" width="30"></v-img>
      Gestión de créditos
    </v-toolbar-title>
    <v-spacer></v-spacer>
    <v-btn
      @click="SalirSistema"
      :style="
        'background-color:' +
        paletColor.botonSubmit2 +
        ';color: ' +
        paletColor.letra1 +
        ';'
      "
      >Salir</v-btn
    >
  </v-toolbar>
  <div class="ma-2">
    <v-row>
      <v-col cols="12" md="3">
        <filtroSolicitudesVue
          @loadItems="loadItems"
          :user="user"
          :usuarioId="usuarioId"
          :ejecutivo="ejecutivo"
          :ejecutivoId="ejecutivoId"
          :heightWindow="widthWindow"
          :widthWindow="widthWindow"
          :isMobile="isMobile"
          @validarMensaje="validarMensaje"
          @setEjecutivo="setEjecutivo"
          @setUsuario="setUsuario"
          @setFullLoading="setFullLoading"
          @SalirSistema="SalirSistema"
      /></v-col>
      <v-col cols="12" md="9" class="align-center">
        <validarCorreoInstitucionalVue
          :user="user"
          :usuarioId="usuarioId"
          :ejecutivo="ejecutivo"
          :ejecutivoId="ejecutivoId"
          :heightWindow="widthWindow"
          :widthWindow="widthWindow"
          :isMobile="isMobile"
          @validarMensaje="validarMensaje"
          @setEjecutivo="setEjecutivo"
          @setUsuario="setUsuario"
          @setFullLoading="setFullLoading"
          @SalirSistema="SalirSistema"
        />
        <validarOpcionPagoVue
          :user="user"
          :usuarioId="usuarioId"
          :ejecutivo="ejecutivo"
          :ejecutivoId="ejecutivoId"
          :heightWindow="widthWindow"
          :widthWindow="widthWindow"
          :isMobile="isMobile"
          @validarMensaje="validarMensaje"
          @setEjecutivo="setEjecutivo"
          @setUsuario="setUsuario"
          @setFullLoading="setFullLoading"
          @SalirSistema="SalirSistema"
          class="bg-light-green-lighten-3"
        />
        <div v-for="items in serverItems" :key="items">
          <solicitudCreditoConsumoVue
            v-for="item in items.data"
            :key="item"
            :nombre="item.persona"
            :descripcion="item.nombre"
            :monto_solicitado="item.monto_solicitado"
            :numero_cuotas="item.numero_cuotas"
            :fecha_primera_cuota="item.fecha_primera_cuota"
            :seguro_desgravament="
              item.seguro_desgravament == '1' ? true : false
            "
            :seguro_cesantia="item.seguro_cesantia == '1' ? true : false"
            :seguro_incapacidad="item.seguro_incapacidad == '1' ? true : false"
            :fecha_creacion="item.fechacrea_at"
            :id="item.id"
            :ofertas="item.cantidadOfertas"
            :paginaRemote="items.current"
            :sueldo="item.sueldo_liquido"
            :TipoCredito="item.TipoCredito"
            :ofertasAceptadas="0"
            @loadItems="loadItems"
            :user="user"
            :usuarioId="usuarioId"
            :ejecutivo="ejecutivo"
            :ejecutivoId="ejecutivoId"
            :heightWindow="widthWindow"
            :widthWindow="widthWindow"
            :isMobile="isMobile"
            @validarMensaje="validarMensaje"
            @setEjecutivo="setEjecutivo"
            @setUsuario="setUsuario"
            @setFullLoading="setFullLoading"
            @SalirSistema="SalirSistema"
          />
          <v-btn
            @click="loadItems(items.next)"
            v-if="maspaginas"
            align="right"
            class="white--text bg-orange-lighten-2 elevation-6"
          >
            Cargar mas solicitudes</v-btn
          >
        </div>
      </v-col>
    </v-row>
  </div>
  <v-dialog
    persistent
    width="auto"
    max-width="70%"
    v-if="dialogSolicitud"
    v-model="dialogSolicitud"
  >
    <v-card class="elevation-6 mt-10">
      <v-card-text> prueba </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-row>
          <v-col cols="12" align="center">
            <v-btn
              variant="outlined"
              class="bg-blue"
              @click="cerrarOferta"
              @reloadInfo="loadItems"
              >Cerrar</v-btn
            >
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { paletColor, obtenerJsonGET } from "@/helpers/funcionesEstandard";
import {
  formatoMonedaChile,
  formatoIDCreditoConsumo,
  formatoFechaTXT,
} from "@/helpers/funcionesEstandard";
import nuevaOfertaVue from "@/components/Banca/creditoConsumo/nuevaOferta.vue";
import solicitudCreditoConsumoVue from "@/components/Banca/creditoConsumo/solicitudCreditoConsumo.vue";
import filtroSolicitudesVue from "../creditoConsumo/filtroSolicitudes.vue";
import validarCorreoInstitucionalVue from "@/components/Banca/Seguridad/validarCorreoInstitucional.vue";
import validarOpcionPagoVue from "../Seguridad/validarOpcionPago.vue";
import buttonCancelformVue from "@/components/form/buttonCancelform.vue";

export default {
  data: () => ({
    dialogOferta: false,
    dialogSolicitud: false,
    itemsPerPage: 10,
    serverItems: [],
    maspaginas: true,
    totalItems: null,
    current: 0,
    next: 0,
    totalPaginas: 0,
    loading: true,
    formatoIDCreditoConsumo: formatoIDCreditoConsumo,
    formatoFechaTXT: formatoFechaTXT,
    filter: null,

    montoSolicitadoSol: null,
    fecPrimCuo: null,
    numCuo: null,
    solCred: null,
    paletColor: paletColor,
  }),
  props: {
    user: Object,
    usuarioId: Number,
    ejecutivo: Object,
    ejecutivoId: Number,
    heightWindow: Number,
    widthWindow: Number,
    isMobile: Boolean,
  },
  components: {
    nuevaOfertaVue,
    solicitudCreditoConsumoVue,
    filtroSolicitudesVue,
    validarCorreoInstitucionalVue,
    validarOpcionPagoVue,
    buttonCancelformVue,
  },
  mounted: function () {
    this.loadItems(1);
  },
  methods: {
    verOfertasReaalizadas: function (id) {
      this.$router.push("/verOfertas/" + id);
    },
    formatoMoneda: formatoMonedaChile,
    openDialogOferta: function (open, id) {
      let idFind = id;
      this.dialogOferta = open;
      if (this.dialogOferta) {
        let found = this.serverItems.find(({ id }) => id == idFind);
        if (found) {
          let mto = found.monto_solicitado.replaceAll(".", "");
          mto = mto.replaceAll("$", "");
          this.montoSolicitadoSol = mto;
          this.fecPrimCuo = found.fecha_primera_cuota;
          this.numCuo = found.numero_cuotas;
          this.solCred = found.id;
        }
      } else {
        if (id > 0) {
          this.loadItems(1);
        }
      }
    },
    loadItems: async function (pagina) {
      if (arguments.length > 1) {
        this.filter = arguments[1];
      }
      if (!this.ejecutivo) {
        this.$router.push("/");
      } else {
        if (!this.ejecutivo.token) {
          this.$router.push("/");
        } else {
          var SendData = {
            usuarioId: this.ejecutivo.usuarioId,
            itemsPerPage: this.itemsPerPage,
            page: pagina,
          };
          if (this.filter) {
            this.serverItems = [];
            for (let key in this.filter) {
              if (this.filter.hasOwnProperty(key)) {
                SendData[key] = this.filter[key];
              }
            }
          }
          var resp = await obtenerJsonGET(
            "principal/obtener_solicitudes_banco",
            SendData
          );
          if (resp.error == 0) {
            let o = {
              data: resp.data,
              current: resp.current,
              next: resp.next,
            };
            if (this.serverItems.length < resp.current) {
              this.serverItems.push(o);
            } else {
              this.serverItems[resp.current - 1] = o;
            }
            if (!resp.next) {
              this.maspaginas = false;
            } else {
              this.maspaginas = true;
            }

            for (
              var x = 0;
              x < this.serverItems[this.serverItems.length - 1].length;
              x++
            ) {
              this.serverItems[this.serverItems.length - 1][
                x
              ].monto_solicitado = formatoMonedaChile(
                this.serverItems[this.serverItems.length - 1][x]
                  .monto_solicitado
              );
              this.serverItems[this.serverItems.length - 1][x].sueldo_liquido =
                formatoMonedaChile(
                  this.serverItems[this.serverItems.length - 1][x]
                    .sueldo_liquido
                );
            }
          } else {
            this.validarMensaje(resp);
          }
          this.loading = false;
        }
      }
    },
    validarMensaje: function (msgAjax) {
      this.$emit("validarMensaje", msgAjax);
    },
    setEjecutivo: function (usuario) {
      this.$emit("setEjecutivo", usuario);
    },
    setUsuario: function (usuario) {
      this.$emit("setUsuario", usuario);
    },
    setFullLoading: function (visible, texto) {
      this.$emit("setFullLoading", visible, texto);
    },
    SalirSistema: function () {
      this.$emit("SalirSistema");
    },
  },
};
</script>


<style scoped>
.floating-card {
  position: fixed;
  top: 150px;
  left: 18px;
  width: 300px; /* Ajusta el ancho según tus necesidades */
  z-index: 1000; /* Asegúrate de que la tarjeta esté sobre otros elementos */
}
</style>